var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from "@emotion/react";
import styled from "@emotion/styled";
export var TextBoxContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var width = _a.width;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\t\t\tpointer-events: auto;\n\t\t\twidth: ", "px;\n\t\t\tdisplay: flex;\n\t\t\tjustify-content: space-between;\n\t\t"], ["\n\t\t\tpointer-events: auto;\n\t\t\twidth: ", "px;\n\t\t\tdisplay: flex;\n\t\t\tjustify-content: space-between;\n\t\t"])), width);
});
var templateObject_1, templateObject_2;

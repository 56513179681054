var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var _a;
import { createSelector } from "@reduxjs/toolkit";
import { NodeComponentType, TubeType } from "./types.current";
import { selectSystemDataRootDefineParameters } from "store/system-data/define-parameters/selectors";
import { isCentralizedSystem } from "store/system-data/helpers";
var ALLOCATED_NODE_IDS = (_a = {},
    _a[NodeComponentType.GRIP_POSITION] = 3,
    _a[NodeComponentType.CENTRALIZED_VACUUM_PUMP] = 1,
    _a[NodeComponentType.FEED_PRESSURE_POINT] = 1,
    _a[NodeComponentType.TUBE_CONNECTION] = 2,
    _a);
var MAX_ID = 257;
var MAX_CENTRALIZED_PUMPS_COUNT = 4;
export var selectSceneRoot = function (state) { return state.scene.present; };
export var selectNodes = function (state) { return selectSceneRoot(state).nodes; };
export var selectEdges = function (state) { return selectSceneRoot(state).edges; };
export var selectAttachments = function (state) {
    return selectSceneRoot(state).attachments;
};
export var selectObjectShape = function (state) {
    return selectSceneRoot(state).objectShape;
};
export var selectObjectSize = function (state) {
    return selectSceneRoot(state).objectSize;
};
export var selectCanUndo = function (state) { return state.scene.past.length > 0; };
export var selectCanRedo = function (state) {
    return state.scene.future.length > 0;
};
export var selectNode = function (nodeId) {
    return createSelector(selectNodes, function (nodes) { return (nodeId ? nodes[nodeId] : undefined); });
};
export var selectEdge = function (edgeId) {
    return createSelector(selectEdges, function (edges) { return (edgeId ? edges[edgeId] : undefined); });
};
export var selectAttachment = function (attachmentId) {
    return createSelector(selectAttachments, function (attachments) {
        return attachmentId ? attachments[attachmentId] : undefined;
    });
};
export var selectTubeConnections = createSelector(selectNodes, function (nodes) {
    return Object.values(nodes).filter(function (node) { return node.type === NodeComponentType.TUBE_CONNECTION; });
});
export var selectGripPositions = createSelector(selectNodes, function (nodes) {
    return Object.values(nodes).filter(function (node) { return node.type === NodeComponentType.GRIP_POSITION; });
});
export var allowAddCentralizedPump = createSelector(selectNodes, function (nodes) {
    var vacuumPump = Object.values(nodes).filter(function (node) { return node.type === NodeComponentType.CENTRALIZED_VACUUM_PUMP; });
    return vacuumPump.length < MAX_CENTRALIZED_PUMPS_COUNT;
});
export var selectEdgeConnections = function (edgeId) {
    return createSelector(selectEdges, selectAttachments, selectNodes, function (edges, attachments, nodes) {
        var edge = edges[edgeId];
        var fromAttachment = attachments[edge.fromAttachmentId];
        var toAttachment = attachments[edge.toAttachmentId];
        return {
            fromAttachment: fromAttachment,
            toAttachment: toAttachment,
            fromNode: nodes[fromAttachment.nodeId],
            toNode: nodes[toAttachment.nodeId],
        };
    });
};
export var selectAvailableIdsCount = createSelector(selectNodes, selectEdges, function (nodes, edges) {
    var nodeIds = Object.values(nodes).reduce(function (total, currentNode) {
        return total + ALLOCATED_NODE_IDS[currentNode.type];
    }, 0);
    var edgeIds = Object.values(edges).reduce(function (total, currentEdge) {
        return (total +
            //Compressed air tubes may be double
            (currentEdge.tubeType === TubeType.COMPRESSED_AIR ? 2 : 1));
    }, 0);
    return MAX_ID - (nodeIds + edgeIds);
});
export var selectHasAvailableIdsForGripPosition = createSelector(selectAvailableIdsCount, function (availableIdsCount) {
    return availableIdsCount >= ALLOCATED_NODE_IDS[NodeComponentType.GRIP_POSITION];
});
export var selectHasAvailableIdsForTubeConnection = createSelector(selectAvailableIdsCount, function (availableIdsCount) {
    return availableIdsCount >= ALLOCATED_NODE_IDS[NodeComponentType.TUBE_CONNECTION];
});
export var selectHasAvailableIdsForPump = createSelector(selectAvailableIdsCount, function (availableIdsCount) {
    return availableIdsCount >=
        ALLOCATED_NODE_IDS[NodeComponentType.CENTRALIZED_VACUUM_PUMP];
});
var gripPositionHasMissingComponents = function (node, systemDataDefineParameters) {
    if (node.type !== NodeComponentType.GRIP_POSITION) {
        return false;
    }
    var systemData = systemDataDefineParameters.dataState.data;
    if (!systemData) {
        return false;
    }
    var selectedCupId = systemDataDefineParameters["cupSelectionMap"][node.id];
    var selectedCup = systemData["suctionCupSelections"][selectedCupId];
    // Centralized system only needs to check if a cup is selected as pumps are individual nodes.
    if (isCentralizedSystem(systemData)) {
        return !selectedCup;
    }
    var selectedPumpId = systemDataDefineParameters["pumpSelectionDecentralMap"][node.id];
    var selectedPump = systemData["vacuumPumpSelectionsDecentralized"][selectedPumpId];
    // Decentralized system could either be missing a pump or a cup for the grip position
    var isEmpty = !selectedCup || !selectedPump;
    return isEmpty;
};
export var selectGripPositionMissingComponent = function (node) {
    return createSelector(selectSystemDataRootDefineParameters, function (systemDataDefineParameters) {
        return gripPositionHasMissingComponents(node, systemDataDefineParameters);
    });
};
export var selectHasGripPositionsWithMissingComponents = createSelector(selectGripPositions, selectSystemDataRootDefineParameters, function (gripPositions, systemDataDefineParameters) {
    var e_1, _a;
    try {
        for (var gripPositions_1 = __values(gripPositions), gripPositions_1_1 = gripPositions_1.next(); !gripPositions_1_1.done; gripPositions_1_1 = gripPositions_1.next()) {
            var node = gripPositions_1_1.value;
            if (gripPositionHasMissingComponents(node, systemDataDefineParameters)) {
                return true;
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (gripPositions_1_1 && !gripPositions_1_1.done && (_a = gripPositions_1.return)) _a.call(gripPositions_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return false;
});
export var selectLatestAddedGripPosition = function (state) {
    return selectSceneRoot(state).latestAddedGripPosition;
};

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { createContext, useContext, useState, useCallback } from "react";
import api from "api/common";
var defaultConfigId = {
    configId: "",
    isLoading: true,
    startConfig: function () {
        //
    },
};
var ConfigIdContext = createContext(defaultConfigId);
export var ConfigIdProvider = function (_a) {
    var children = _a.children;
    var _b = __read(useState(defaultConfigId.configId), 2), configId = _b[0], setConfigId = _b[1];
    var _c = __read(useState(), 2), ticket = _c[0], setTicket = _c[1];
    var _d = __read(useState(true), 2), isLoading = _d[0], setIsLoading = _d[1];
    var _e = __read(useState(), 2), error = _e[0], setError = _e[1];
    var startConfig = useCallback(function (ticket) {
        var fetchConfig = function () { return __awaiter(void 0, void 0, void 0, function () {
            var startedConfigId, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        ticket
                            ? sessionStorage.setItem("ticket", ticket)
                            : window.sessionStorage.removeItem("ticket");
                        setTicket(ticket);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, api.startConfiguration(ticket)];
                    case 2:
                        startedConfigId = _a.sent();
                        setConfigId(startedConfigId);
                        window.localStorage.setItem("configId", startedConfigId);
                        setIsLoading(false);
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        if (ticket) {
                            // in case of an error we need to clear the ticket, so that the user does not get stuck with a bad ticket and a page that will never load successfully.
                            console.warn("Failed to start config. Clearing ticket.");
                            window.sessionStorage.removeItem("ticket");
                        }
                        setError(err_1);
                        setIsLoading(false);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        fetchConfig();
    }, []);
    return (_jsx(ConfigIdContext.Provider, { value: {
            configId: configId,
            ticket: ticket,
            isLoading: isLoading,
            error: error,
            startConfig: startConfig,
        }, children: children }));
};
export var useConfigIdContext = function () { return useContext(ConfigIdContext); };

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { ImageSelect, Label, TreeNavDivider } from "@animech/ui";
import * as Styled from "ui/components/panels/sidebar/sidebar.style";
import { useAppDispatch, useAppSelector, selectors, actions } from "store";
import { Unit } from "utility/number";
import { useNumberConversionWithMillimeters } from "hooks/number-conversion";
import { ConvertedNumberInput } from "ui/components/elements/input/converted-number-input";
import { objectShapes } from "3d/constants/common";
import { AdjustableObjectType } from "3d/types";
import { ParameterInputGroup } from "ui/components/elements/parameter-input";
export var ObjectParameterMenu = function () {
    var _a, _b, _c, _d, _e, _f;
    var t = useTranslation().t;
    var dispatch = useAppDispatch();
    var numberConverter = useNumberConversionWithMillimeters();
    var objectShapeId = useAppSelector(selectors.selectObjectShape);
    var objectSize = useAppSelector(selectors.selectObjectSize);
    var objectShape = objectShapes[objectShapeId];
    var activeObjectShapes = useAppSelector(selectors.selectObjectShapes);
    var adjustableObject = objectShape.adjustableObject;
    var onChangeShape = function (value) {
        dispatch(actions.setObjectShape(value));
    };
    var onChangeLength = function (length) {
        dispatch(actions.setObjectSize({
            x: length,
            y: objectSize.y,
            z: objectSize.z,
        }));
    };
    var onChangeWidth = function (width) {
        dispatch(actions.setObjectSize({
            x: objectSize.x,
            y: objectSize.y,
            z: width,
        }));
    };
    var onChangeDiameter = function (diameter) {
        dispatch(actions.setObjectSize({
            x: objectSize.x,
            y: diameter,
            z: diameter,
        }));
    };
    return (_jsxs(_Fragment, { children: [adjustableObject && (_jsxs(_Fragment, { children: [_jsx(TreeNavDivider, {}), _jsxs(ParameterInputGroup, { children: [_jsx("div", { children: _jsx(ConvertedNumberInput, { value: objectSize.x, label: t("sidebar.object_shape.shape_length"), min: (_a = objectShape.sizeRange) === null || _a === void 0 ? void 0 : _a.min.x, max: (_b = objectShape.sizeRange) === null || _b === void 0 ? void 0 : _b.max.x, setValue: onChangeLength, type: "float", customConverter: numberConverter, unit: Unit.M, instantUpdate: true }) }), adjustableObject.type === AdjustableObjectType.CYLINDER ? (_jsx("div", { children: _jsx(ConvertedNumberInput, { value: objectSize.z, label: t("sidebar.object_shape.shape_diameter"), min: (_c = objectShape.sizeRange) === null || _c === void 0 ? void 0 : _c.min.y, max: (_d = objectShape.sizeRange) === null || _d === void 0 ? void 0 : _d.max.y, setValue: onChangeDiameter, type: "float", unit: Unit.M, customConverter: numberConverter, instantUpdate: true }) })) : (_jsx("div", { children: _jsx(ConvertedNumberInput, { value: objectSize.z, label: t("sidebar.object_shape.shape_width"), min: (_e = objectShape.sizeRange) === null || _e === void 0 ? void 0 : _e.min.y, max: (_f = objectShape.sizeRange) === null || _f === void 0 ? void 0 : _f.max.y, setValue: onChangeWidth, type: "float", unit: Unit.M, customConverter: numberConverter, instantUpdate: true }) }))] })] })), _jsx(TreeNavDivider, {}), _jsxs("div", { children: [_jsxs(Label, { children: [" ", t("sidebar.object_shape.shape_dropdown_label"), " "] }), _jsx(ImageSelect, { className: "".concat(Styled.prefix, "-image-select"), itemImages: Object.values(activeObjectShapes).map(function (entry) { return entry.image; }), itemLabels: Object.values(activeObjectShapes).map(function (entry) {
                            return t(entry.translationKey);
                        }), items: Object.keys(activeObjectShapes), value: objectShapeId, onValueChange: onChangeShape, gridItemSize: 3, gridSpacing: "large" })] })] }));
};

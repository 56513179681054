import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Button, TextBox } from "@animech/ui";
import { useTranslation } from "react-i18next";
import * as Styled from "./developer-ticket.style";
import { useConfigIdContext } from "providers/config-id";
import { BuildInfo } from "utility/buildinfo";
export var DeveloperTicket = function () {
    var _a = useConfigIdContext(), startConfig = _a.startConfig, ticket = _a.ticket;
    var t = useTranslation().t;
    var onTicketChange = function (newTicket) {
        startConfig(newTicket);
    };
    var isProdEnvironment = BuildInfo.environment === "production";
    if (!ticket || isProdEnvironment)
        return null;
    return (_jsxs(Styled.DeveloperTicket, { children: [_jsx(Styled.DeveloperTicketText, { children: t("developer.ticket.text") }), _jsx(TextBox, { value: ticket, color: "neutral", onValueChange: onTicketChange }), _jsx(Button, { onClick: function () { return startConfig(); }, size: "small", children: t("developer.ticket.clear") })] }));
};

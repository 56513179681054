import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import * as Styled from "./tab-header.style";
export var TabHeader = function (_a) {
    var header = _a.header;
    return _jsx(Styled.TabHeader, { children: header });
};
export var TabSubHeader = function (_a) {
    var header = _a.header;
    return _jsx(Styled.TabSubHeader, { children: header });
};

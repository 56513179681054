var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { useEffect, useState } from "react";
import * as pc from "playcanvas";
import { TooltipPortal } from "@animech/ui";
import { useTranslation } from "react-i18next";
import { Unit } from "utility/number";
import { useAssetLoader } from "hooks/playcanvas/asset-loader";
import { useSystemPerformanceValidityMessage } from "hooks/messages";
import { MessagesPopover, MessageLevel, } from "ui/components/elements/messages-popover";
import { StyledCanvasOverlay } from "ui/components/panels/canvas/overlay-elements/canvas-overlay";
import { CameraViewId } from "3d/types";
import { NodeComponentType, TubeType, } from "store/types";
import { useAppDispatch, useAppSelector, selectors, actions } from "store";
import { centralizedVacuumPumps, defaultCentralPumpModel, } from "3d/constants/common";
import { useNumberConversion } from "hooks/number-conversion";
import { CustomUITooltip } from "ui/components/elements/custom-ui-tooltip";
import { PsEsTooltip } from "ui/components/panels/canvas/overlay-elements/ps-es-tooltip";
var PUMP_ENTITY_NAME = "Pump";
var TOOLTIP_MARGIN = 10;
export var CentralizedVacuumPump = function (_a) {
    var app = _a.app, nodeEntity = _a.nodeEntity, modelAssets = _a.modelAssets, node = _a.node, isMoving = _a.isMoving, position = _a.position;
    var isCentralizedSystem = useAppSelector(selectors.selectIsCentralizedSystem);
    var isSystemPerformanceLoading = useAppSelector(selectors.selectIsPerformanceLoading);
    var hasReachedPartSecured = useAppSelector(selectors.selectHasReachedPartSecured);
    var isPerformanceDataValid = useAppSelector(selectors.selectIsPerformanceDataValid);
    var selectedIds = useAppSelector(selectors.selectSelectedIds);
    var convertNumber = useNumberConversion();
    var dispatch = useAppDispatch();
    var loadAssets = useAssetLoader(app).loadAssets;
    var t = useTranslation().t;
    var vacuumPump = node.component;
    var _b = __read(useState(null), 2), incomingTooltip = _b[0], setIncomingTooltip = _b[1];
    var _c = __read(useState(null), 2), outgoingTooltip = _c[0], setOutgoingTooltip = _c[1];
    var selectedPump = useAppSelector(selectors.selectSelectedCentralPump(node.id));
    var selectedPumpCadName = selectedPump === null || selectedPump === void 0 ? void 0 : selectedPump.cadName;
    var selectedPumpModel = (selectedPumpCadName && centralizedVacuumPumps[selectedPumpCadName]) ||
        defaultCentralPumpModel;
    var selectedNode = useAppSelector(selectors.selectSelectedNode);
    var selectedNodeIsRegulator = (selectedNode === null || selectedNode === void 0 ? void 0 : selectedNode.type) === NodeComponentType.FEED_PRESSURE_POINT;
    var performanceData = useAppSelector(selectors.selectVacuumPumpPerformance(node.id));
    var systemPerformanceMessage = useSystemPerformanceValidityMessage();
    useEffect(function () {
        var pumpEntity = new pc.Entity(PUMP_ENTITY_NAME);
        pumpEntity.addComponent("model", { type: "asset" });
        nodeEntity.addChild(pumpEntity);
        return function () {
            pumpEntity.destroy();
        };
    }, [nodeEntity]);
    useEffect(function () {
        var pumpEntity = nodeEntity.findByName(PUMP_ENTITY_NAME);
        if (!pumpEntity)
            return;
        var pumpAsset = modelAssets[selectedPumpModel.modelName];
        var updatePump = function () {
            if (pumpEntity.model && pumpAsset) {
                pumpEntity.model.asset = pumpAsset;
            }
            /* Should hopefully never be false but if the Tacton response is unsuccessful and no pump is selected,
             * we still display a pump since it exists in the state. But it will have no attachments. It would be better
             * to display a placeholder or maybe delete the node but instead let's hope it does not happen :) */
            dispatch(actions.setNodeAttachments({
                nodeId: vacuumPump.nodeId,
                placeholders: [
                    {
                        name: selectedPumpModel.placeholderFront,
                        tubeType: TubeType.VACUUM,
                    },
                    {
                        name: selectedPumpModel.placeholderBack,
                        tubeType: TubeType.COMPRESSED_AIR,
                    },
                ],
            }));
        };
        if (pumpAsset) {
            loadAssets([pumpAsset], updatePump);
        }
    }, [
        selectedPumpModel,
        nodeEntity,
        modelAssets,
        loadAssets,
        dispatch,
        vacuumPump.nodeId,
    ]);
    useEffect(function () {
        if (isCentralizedSystem) {
            nodeEntity.enabled = true;
        }
        else {
            nodeEntity.enabled = false;
        }
    }, [isCentralizedSystem, nodeEntity]);
    var cameraViewId = useAppSelector(selectors.selectCameraViewId);
    // Calculate position and determine if tooltips are visible for in/out air-pressure.
    useEffect(function () {
        // If regulator is not selected or zoomed in we dont render any tooltips.
        if (!nodeEntity ||
            !selectedNodeIsRegulator ||
            isMoving ||
            !performanceData ||
            cameraViewId === CameraViewId.NODE_VIEW ||
            isSystemPerformanceLoading) {
            setIncomingTooltip(null);
            setOutgoingTooltip(null);
            return;
        }
        var cameraEntity = app.root.findByName("Camera");
        var camera = cameraEntity === null || cameraEntity === void 0 ? void 0 : cameraEntity.camera;
        if (!camera || !nodeEntity)
            return;
        var incomingPressure = performanceData.compressedAirPressure;
        var outgoingPressure = performanceData.freeRunningVacuumLevel;
        var getAttachmentPointScreenPosition = function (placeholderName) {
            var attachmentPointEntity = nodeEntity.findByName(placeholderName);
            return (attachmentPointEntity &&
                camera.worldToScreen(attachmentPointEntity.getPosition()));
        };
        var incomingTooltipPosition = getAttachmentPointScreenPosition(selectedPumpModel.placeholderBack);
        if (incomingTooltipPosition) {
            setIncomingTooltip(function () { return ({
                x: incomingTooltipPosition.x,
                y: incomingTooltipPosition.y - TOOLTIP_MARGIN,
                text: "".concat(t("canvas.compressed_air_pressure_pump"), ": ").concat(convertNumber(incomingPressure, Unit.MPA).formatted, "\n\t\t\t "),
                placement: "top",
            }); });
        }
        var outgoingTooltipPosition = getAttachmentPointScreenPosition(selectedPumpModel.placeholderFront);
        if (outgoingTooltipPosition) {
            setOutgoingTooltip(function () { return ({
                x: outgoingTooltipPosition.x,
                y: outgoingTooltipPosition.y + TOOLTIP_MARGIN,
                text: "".concat(t("canvas.free_running_vacuum_pump"), ": ").concat(convertNumber(outgoingPressure, Unit.KPA).formatted, "\n\t\t\t\t "),
                placement: "bottom",
            }); });
        }
    }, [
        nodeEntity,
        app,
        isMoving,
        selectedNodeIsRegulator,
        node.id,
        t,
        performanceData,
        cameraViewId,
        isSystemPerformanceLoading,
        selectedPumpModel,
        convertNumber,
    ]);
    var tooltips = [incomingTooltip, outgoingTooltip].filter(Boolean);
    if (selectedNodeIsRegulator) {
        return (_jsx(_Fragment, { children: tooltips.map(function (tooltip) {
                return (tooltip.x &&
                    tooltip.y && (_jsx(_Fragment, { children: _jsx(TooltipPortal, { placement: tooltip.placement, 
                        /* We need to access the styled component directly when used as an anchor element
                         * Otherwise the position cannot be calculated properly for the TooltipPortal */
                        anchorElement: _jsx(StyledCanvasOverlay, { x: tooltip.x, y: tooltip.y }), open: true, children: _jsx(CustomUITooltip, { children: _jsxs("div", { style: {
                                    display: "flex",
                                    alignItems: "center",
                                    pointerEvents: "all",
                                }, children: [tooltip.text, systemPerformanceMessage && (_jsx(MessagesPopover, { messages: [systemPerformanceMessage], level: MessageLevel.WARNING, toggleEvent: "hover", size: "small" }))] }) }) }, tooltip.text) })));
            }) }));
    }
    var showPartSecured = isCentralizedSystem &&
        hasReachedPartSecured &&
        isPerformanceDataValid &&
        selectedIds.length === 0 &&
        !isMoving;
    return showPartSecured && position ? (_jsx(PsEsTooltip, { anchorX: position.x, anchorY: position.y })) : null;
};

var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import * as Styled from "./define-cardboard.style";
import { CardboardWizardImage } from "./cardboard-wizard-image";
import { CardboardImageSelectAccordions } from "./cardboard-image-select";
import { PageRoute } from "utility/url";
import { CardboardCode } from "ui/components/elements/cardboard-code";
import { ChoiceType } from "api/types";
import { selectors, useAppSelector } from "store";
import { ParameterInput } from "ui/components/elements/parameter-input";
import { cardboardParameterNames } from "api/tacton/constants";
import { QualitySliders } from "ui/components/modals/define-cardboard/quality-slider/quality-slider";
import { TabHeader } from "ui/components/panels/home-menu/tabs/tab-header";
export var DefineCardboardContainer = function () {
    var defineCardboardGroup = useAppSelector(selectors.selectGroupedParametersDefineCardboard);
    var _a = __read(useState([]), 2), dropdownItems = _a[0], setDropdownItems = _a[1];
    var isDefineParametersPage = useLocation().pathname === PageRoute.DEFINE_PARAMETERS;
    useEffect(function () {
        var e_1, _a;
        if (!defineCardboardGroup)
            return;
        var dropdownParameters = [];
        try {
            for (var _b = __values(defineCardboardGroup.parameters), _c = _b.next(); !_c.done; _c = _b.next()) {
                var parameter = _c.value;
                if (!parameter)
                    continue;
                /* The cardboard_code_field & end_tape_field will have type "dropdown" from tacton. But we don't
                 * want to add it to the list of dropdowns, as we will render its value in a separate component.
                 * That's why we need to perform this comparison first.*/
                if (parameter.id === cardboardParameterNames.cardboardCode ||
                    parameter.id === cardboardParameterNames.cardboardTapeEnds) {
                    continue;
                }
                else if (parameter.type === ChoiceType.DROPDOWN) {
                    dropdownParameters.push(parameter);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        setDropdownItems(dropdownParameters);
    }, [defineCardboardGroup]);
    var dropdownAccordions = useMemo(function () {
        return dropdownItems.map(function (item) {
            return _jsx(ParameterInput, { parameter: item }, item.id);
        });
    }, [dropdownItems]);
    if (!defineCardboardGroup)
        return null;
    if (!isDefineParametersPage) {
        return (_jsx(CardboardSidebar, { dropdownAccordions: dropdownAccordions, showCodeComponent: true }));
    }
    return (_jsxs(Styled.ModalContainer, { children: [_jsx(TabHeader, { header: (defineCardboardGroup === null || defineCardboardGroup === void 0 ? void 0 : defineCardboardGroup.title) || "" }), _jsxs(Styled.Root, { children: [_jsx(CardboardSidebar, { dropdownAccordions: dropdownAccordions }), _jsxs(Styled.MainContent, { children: [_jsx(CardboardWizardImage, {}), _jsx(CardboardCode, {})] })] })] }));
};
export var CardboardSidebar = function (_a) {
    var dropdownAccordions = _a.dropdownAccordions, _b = _a.showCodeComponent, showCodeComponent = _b === void 0 ? false : _b;
    return (_jsxs(Styled.Sidebar, { children: [_jsx(Styled.AccordionList, { children: _jsxs(_Fragment, { children: [dropdownAccordions, _jsx(CardboardImageSelectAccordions, {})] }) }), _jsx(Styled.Sliders, { children: _jsx(QualitySliders, {}) }), showCodeComponent && _jsx(CardboardCode, {})] }));
};

import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { actions, selectors, useAppDispatch, useAppSelector } from "store";
import { parameterNames } from "api/tacton/constants";
import { ConvertedNumberInput } from "ui/components/elements/input/converted-number-input";
import { Unit } from "utility/number";
/*
    There is no SecondsPerCycle parameter but this component lets the user enter SecondsPerCycle and then
    commits the values to the PartsPerMinute-parameter.

    So the purpose of this component is to convert values between Parts per minute and Seconds per cycle.
*/
export var EditSecondsPerCycle = function () {
    var t = useTranslation().t;
    var dispatch = useAppDispatch();
    var systemDataDefineParameters = useAppSelector(selectors.selectSystemDataDefineParameters);
    var parameter = systemDataDefineParameters === null || systemDataDefineParameters === void 0 ? void 0 : systemDataDefineParameters.parameters[parameterNames.targetPartsPerMinute];
    var setValue = useCallback(function (secondsPerCycle) {
        if (!(parameter === null || parameter === void 0 ? void 0 : parameter.id)) {
            return;
        }
        var partsPerMinute = 60 / secondsPerCycle;
        dispatch(actions.setParametersDefineParameters({
            parameters: [parameter.id],
            values: [partsPerMinute.toString()],
        }));
    }, [dispatch, parameter === null || parameter === void 0 ? void 0 : parameter.id]);
    if (!parameter || !parameter.min || !parameter.max) {
        return null;
    }
    var partsPerMinute = Number.parseFloat(parameter.value);
    var secondsPerCycle = 60 / partsPerMinute;
    var secondsPerCycleMax = 60 / parameter.min; // the maximum value for secondsPerCycle is when we have the minimum allowed value for partsPerMinute
    var secondsPerCycleMin = 60 / parameter.max;
    return (_jsx(ConvertedNumberInput, { autoFocus: true, label: t("dashboard.sec_per_cycle"), description: parameter.description, min: secondsPerCycleMin, max: secondsPerCycleMax, type: "float", setValue: setValue, value: secondsPerCycle, unit: Unit.S }));
};

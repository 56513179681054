import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useMemo } from "react";
import { LoadingOverlay } from "ui/components/elements/loading-overlay";
import { DelayedFallback } from "ui/utility/delayed-fallback";
/**
 * A function that will render the given component if the user is authenticated,
 * or a loading overlay if the user is not yet authenticated.
 * @param component The component to render if the user is authenticated.
 * @param returnTo The URL to redirect to after authentication.
 * @returns The given component if the user is authenticated or a loading
 * overlay if the user is not yet authenticated.
 */
export var Auth0AuthenticationGuard = function (_a) {
    var component = _a.component, returnTo = _a.returnTo;
    var Component = useMemo(function () {
        return withAuthenticationRequired(component, {
            onRedirecting: function () { return (_jsx(DelayedFallback, { isLoading: true, fallback: _jsx(LoadingOverlay, {}) })); },
            returnTo: returnTo,
        });
    }, [component, returnTo]);
    return _jsx(Component, {});
};

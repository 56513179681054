var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from "@emotion/react";
import styled from "@emotion/styled";
export var Options = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme, rowLimit = _a.rowLimit;
    var gap = "".concat(theme.dimensions.margin.small, " ").concat(theme.dimensions.margin.large);
    var padding = "".concat(theme.dimensions.margin.base, "\n\t\t\t\t\t").concat(theme.dimensions.margin.small);
    if (rowLimit) {
        return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\t\t\t\tdisplay: grid;\n\t\t\t\tgap: ", ";\n\t\t\t\tpadding: ", ";\n\t\t\t\tgrid-template-columns: repeat(", ", min-content);\n\t\t\t"], ["\n\t\t\t\tdisplay: grid;\n\t\t\t\tgap: ", ";\n\t\t\t\tpadding: ", ";\n\t\t\t\tgrid-template-columns: repeat(", ", min-content);\n\t\t\t"])), gap, padding, rowLimit);
    }
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\t\t\tdisplay: flex;\n\t\t\tgap: ", ";\n\t\t\tpadding: ", ";\n\t\t\tflex-wrap: wrap;\n\t\t"], ["\n\t\t\tdisplay: flex;\n\t\t\tgap: ", ";\n\t\t\tpadding: ", ";\n\t\t\tflex-wrap: wrap;\n\t\t"])), gap, padding);
});
var templateObject_1, templateObject_2, templateObject_3;

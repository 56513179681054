var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var _c;
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { TreeNavItem } from "@animech/ui";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { actions, selectors, useAppDispatch, useAppSelector } from "store";
import { NodeComponentType, TubeType, } from "store/types";
import { CustomTreeNav } from "ui/components/elements/custom-treenav";
import * as Styled from "ui/components/panels/sidebar/sidebar.style";
var SelectionTubeType;
(function (SelectionTubeType) {
    SelectionTubeType["EDGE_VACUUM"] = "edge~vacuum";
    SelectionTubeType["EDGE_COMPRESSED_AIR"] = "edge~compressed_air";
    SelectionTubeType["EDGE_UNKNOWN"] = "edge~unknown";
    SelectionTubeType["TUBE_CONNECTION_VACUUM"] = "tube_connection~vacuum";
    SelectionTubeType["TUBE_CONNECTION_COMPRESSED_AIR"] = "tube_connection~compressed_air";
    SelectionTubeType["TUBE_CONNECTION_UNKNOWN"] = "tube_connection~unknown";
})(SelectionTubeType || (SelectionTubeType = {}));
var DecentralizedPumpType = "decentralized_pump";
var textColorFromTubeType = {
    // eslint-disable-next-line camelcase
    compressed_air: "#c9372c",
    vacuum: "#1d7afc",
};
var orderedSelectionTypes = (_c = {},
    _c[NodeComponentType.GRIP_POSITION] = 1,
    _c[SelectionTubeType.EDGE_UNKNOWN] = 2,
    _c[SelectionTubeType.TUBE_CONNECTION_UNKNOWN] = 3,
    _c[NodeComponentType.TUBE_CONNECTION] = 4,
    _c[SelectionTubeType.EDGE_VACUUM] = 5,
    _c[SelectionTubeType.TUBE_CONNECTION_VACUUM] = 6,
    _c[NodeComponentType.CENTRALIZED_VACUUM_PUMP] = 7,
    _c[DecentralizedPumpType] = 8,
    _c[SelectionTubeType.EDGE_COMPRESSED_AIR] = 9,
    _c[SelectionTubeType.TUBE_CONNECTION_COMPRESSED_AIR] = 10,
    _c[NodeComponentType.FEED_PRESSURE_POINT] = 11,
    _c);
var sortEntitiesByType = function (typeA, typeB) {
    var orderA = orderedSelectionTypes[typeA];
    var orderB = orderedSelectionTypes[typeB];
    return orderA - orderB;
};
export var MixedSelectionMenu = function (_c) {
    var nodes = _c.nodes, edges = _c.edges;
    var t = useTranslation().t;
    var dispatch = useAppDispatch();
    var decentralPumps = useAppSelector(selectors.selectSelectedDecentralPumps(nodes.map(function (node) { return node.id; })));
    var nodeIdToDecentralPump = useMemo(function () {
        return nodes.reduce(function (acc, node, i) {
            var _c;
            return (__assign(__assign({}, acc), (_c = {}, _c[node.id] = decentralPumps[i], _c)));
        }, {});
    }, [decentralPumps, nodes]);
    var isDecentralizedSystem = !useAppSelector(selectors.selectIsCentralizedSystem);
    var nodeEntityMap = useMemo(function () {
        var newNodeEntityMap = new Map();
        nodes.forEach(function (node) {
            var _c, _d, _e, _f;
            // create or update the array of entities for the given type
            var type = node.type;
            if (type === NodeComponentType.TUBE_CONNECTION) {
                var tubeConnectionComponent = node.component;
                var tubeType = tubeConnectionComponent.tubeType;
                var selectionTubeType = SelectionTubeType.TUBE_CONNECTION_UNKNOWN;
                switch (tubeType) {
                    case TubeType.VACUUM:
                        selectionTubeType = SelectionTubeType.TUBE_CONNECTION_VACUUM;
                        break;
                    case TubeType.COMPRESSED_AIR:
                        selectionTubeType =
                            SelectionTubeType.TUBE_CONNECTION_COMPRESSED_AIR;
                        break;
                }
                var current = (_c = newNodeEntityMap.get(selectionTubeType)) !== null && _c !== void 0 ? _c : [];
                newNodeEntityMap.set(selectionTubeType, __spreadArray(__spreadArray([], __read(current), false), [node], false));
            }
            else if (isDecentralizedSystem &&
                type === NodeComponentType.GRIP_POSITION) {
                var current = (_d = newNodeEntityMap.get(type)) !== null && _d !== void 0 ? _d : [];
                newNodeEntityMap.set(type, __spreadArray(__spreadArray([], __read(current), false), [node], false));
                if (nodeIdToDecentralPump[node.id]) {
                    /* If system is Decentralized, we need to duplicate grip positions
                     * so that we can select from both Suction Cups and Vacuum Pumps in the mixed selection menu. */
                    var currentDecentralizedPump = (_e = newNodeEntityMap.get(DecentralizedPumpType)) !== null && _e !== void 0 ? _e : [];
                    newNodeEntityMap.set(DecentralizedPumpType, __spreadArray(__spreadArray([], __read(currentDecentralizedPump), false), [
                        node,
                    ], false));
                }
            }
            else {
                var current = (_f = newNodeEntityMap.get(type)) !== null && _f !== void 0 ? _f : [];
                newNodeEntityMap.set(type, __spreadArray(__spreadArray([], __read(current), false), [node], false));
            }
        });
        return newNodeEntityMap;
    }, [isDecentralizedSystem, nodeIdToDecentralPump, nodes]);
    var edgeEntityMap = useMemo(function () {
        var e_1, _c;
        if (!edges.length) {
            return;
        }
        var newEdgeEntityMap = new Map();
        var vacuumEdges = [];
        var pressureEdges = [];
        var unkownEdges = [];
        try {
            for (var edges_1 = __values(edges), edges_1_1 = edges_1.next(); !edges_1_1.done; edges_1_1 = edges_1.next()) {
                var edge = edges_1_1.value;
                switch (edge.tubeType) {
                    case TubeType.VACUUM:
                        vacuumEdges.push(edge);
                        break;
                    case TubeType.COMPRESSED_AIR:
                        pressureEdges.push(edge);
                        break;
                    default:
                        unkownEdges.push(edge);
                        break;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (edges_1_1 && !edges_1_1.done && (_c = edges_1.return)) _c.call(edges_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        vacuumEdges.length &&
            newEdgeEntityMap.set(SelectionTubeType.EDGE_VACUUM, __spreadArray([], __read(vacuumEdges), false));
        pressureEdges.length &&
            newEdgeEntityMap.set(SelectionTubeType.EDGE_COMPRESSED_AIR, __spreadArray([], __read(pressureEdges), false));
        unkownEdges.length &&
            newEdgeEntityMap.set(SelectionTubeType.EDGE_UNKNOWN, __spreadArray([], __read(unkownEdges), false));
        return newEdgeEntityMap;
    }, [edges]);
    var _d = __read(useMemo(function () {
        var nodeEntites = Array.from(nodeEntityMap.entries());
        var edgeEntities = edgeEntityMap
            ? Array.from(edgeEntityMap.entries())
            : [];
        var entityByTypeMap = new Map(__spreadArray(__spreadArray([], __read(nodeEntites), false), __read(edgeEntities), false));
        var sortedTypesAndEntities = __spreadArray(__spreadArray([], __read(nodeEntites), false), __read(edgeEntities), false).sort(function (_c, _d) {
            var _e = __read(_c, 2), typeA = _e[0], _a = _e[1];
            var _f = __read(_d, 2), typeB = _f[0], _b = _f[1];
            return sortEntitiesByType(typeA, typeB);
        });
        return [entityByTypeMap, sortedTypesAndEntities];
    }, [nodeEntityMap, edgeEntityMap]), 2), entitiesByType = _d[0], typesAndEntities = _d[1];
    var handleActiveItemChange = function (type) {
        if (!type)
            return;
        var key = type;
        var entities = entitiesByType.get(key);
        if (!entities)
            return;
        var entityIds = entities.map(function (node) { return node.id; });
        dispatch(actions.selectEntities(entityIds));
    };
    return (_jsx(Styled.SidebarSection, { children: _jsx(CustomTreeNav, { onActiveItemChange: handleActiveItemChange, children: typesAndEntities.map(function (_c) {
                var _d = __read(_c, 2), key = _d[0], entities = _d[1];
                var type = key.split("~")[0];
                var tubeType = key.split("~")[1];
                var textColor = textColorFromTubeType[tubeType];
                var label = (_jsxs(_Fragment, { children: [_jsx("span", { style: textColor ? { color: textColor } : undefined, children: t("node_component_types." + type, {
                                count: entities.length,
                            }) }), _jsx("span", { style: { marginLeft: "4px" }, children: t("node_component_types.ammount", {
                                count: entities.length,
                            }) })] }));
                return _jsx(TreeNavItem, { id: key, label: label }, key);
            }) }) }));
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Container } from "@animech/ui";
import { Dropdown as StyledDropdown } from "ui/components/elements/input/dropdown/dropdown.style";
import { StyledCardboardCode } from "ui/components/elements/cardboard-code";
var baseMargin = "20px";
export var ModalContainer = styled(Container)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tpadding: 0;\n"], ["\n\tpadding: 0;\n"])));
export var Sidebar = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function () {
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\t\t\tdisplay: grid;\n\t\t\tgrid-template-rows: 1fr auto;\n\t\t\tpadding: ", " 0;\n\t\t\tgap: 12px;\n\t\t\tbackground-color: transparent;\n\t\t\theight: inherit;\n\n\t\t\t", ",\n\t\t\t", " {\n\t\t\t\tdisplay: grid;\n\t\t\t\tgrid-auto-rows: max-content;\n\t\t\t\tgap: 10px;\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\theight: 45px;\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tflex-direction: row;\n\t\t\t\tgap: 22px;\n\t\t\t\tborder-top: 1px solid #5a5a5a;\n\t\t\t\tpadding-top: 24px;\n\t\t\t\tmargin: 0 12px;\n\n\t\t\t\t", " {\n\t\t\t\t\tfont-size: 14px;\n\t\t\t\t}\n\t\t\t\t", " {\n\t\t\t\t\tfont-size: 40px;\n\t\t\t\t}\n\t\t\t}\n\t\t"], ["\n\t\t\tdisplay: grid;\n\t\t\tgrid-template-rows: 1fr auto;\n\t\t\tpadding: ", " 0;\n\t\t\tgap: 12px;\n\t\t\tbackground-color: transparent;\n\t\t\theight: inherit;\n\n\t\t\t", ",\n\t\t\t", " {\n\t\t\t\tdisplay: grid;\n\t\t\t\tgrid-auto-rows: max-content;\n\t\t\t\tgap: 10px;\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\theight: 45px;\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tflex-direction: row;\n\t\t\t\tgap: 22px;\n\t\t\t\tborder-top: 1px solid #5a5a5a;\n\t\t\t\tpadding-top: 24px;\n\t\t\t\tmargin: 0 12px;\n\n\t\t\t\t", " {\n\t\t\t\t\tfont-size: 14px;\n\t\t\t\t}\n\t\t\t\t", " {\n\t\t\t\t\tfont-size: 40px;\n\t\t\t\t}\n\t\t\t}\n\t\t"])), baseMargin, Sliders, AccordionList, StyledDropdown, StyledCardboardCode.CardboardCodeRoot, StyledCardboardCode.Label, StyledCardboardCode.Code);
});
export var MainContent = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
export var ImageContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject([""], [""])));
export var Image = styled.img(templateObject_6 || (templateObject_6 = __makeTemplateObject([""], [""])));
export var AccordionList = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject([""], [""])));
export var Sliders = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject([""], [""])));
export var Root = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function () {
    return css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n\t\t\tdisplay: grid;\n\t\t\tgrid-template-columns: 33% 1fr;\n\t\t\taspect-ratio: 2/1.3;\n\n\t\t\t", " {\n\t\t\t\tpadding: ", ";\n\t\t\t\tdisplay: grid;\n\t\t\t\tgrid-template-rows: 8% 60% 1fr;\n\t\t\t\tgap: 2em;\n\t\t\t\tbackground: linear-gradient(0deg, #2a2d30, #363b3e);\n\t\t\t\toverflow-y: hidden;\n\n\t\t\t\t", " {\n\t\t\t\t\tgrid-row: 2;\n\t\t\t\t\tdisplay: flex;\n\t\t\t\t\tjustify-content: center;\n\t\t\t\t}\n\n\t\t\t\t", " {\n\t\t\t\t\tgrid-row: 3;\n\t\t\t\t}\n\n\t\t\t\t", " {\n\t\t\t\t\tobject-fit: contain;\n\t\t\t\t\twidth: 100%;\n\t\t\t\t\theight: 100%;\n\t\t\t\t}\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\toverflow: auto;\n\t\t\t\tpadding: ", " 5px;\n\t\t\t\t", ",\n\t\t\t\t", " {\n\t\t\t\t\tpadding: 0 25px;\n\t\t\t\t}\n\t\t\t}\n\t\t"], ["\n\t\t\tdisplay: grid;\n\t\t\tgrid-template-columns: 33% 1fr;\n\t\t\taspect-ratio: 2/1.3;\n\n\t\t\t", " {\n\t\t\t\tpadding: ", ";\n\t\t\t\tdisplay: grid;\n\t\t\t\tgrid-template-rows: 8% 60% 1fr;\n\t\t\t\tgap: 2em;\n\t\t\t\tbackground: linear-gradient(0deg, #2a2d30, #363b3e);\n\t\t\t\toverflow-y: hidden;\n\n\t\t\t\t", " {\n\t\t\t\t\tgrid-row: 2;\n\t\t\t\t\tdisplay: flex;\n\t\t\t\t\tjustify-content: center;\n\t\t\t\t}\n\n\t\t\t\t", " {\n\t\t\t\t\tgrid-row: 3;\n\t\t\t\t}\n\n\t\t\t\t", " {\n\t\t\t\t\tobject-fit: contain;\n\t\t\t\t\twidth: 100%;\n\t\t\t\t\theight: 100%;\n\t\t\t\t}\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\toverflow: auto;\n\t\t\t\tpadding: ", " 5px;\n\t\t\t\t", ",\n\t\t\t\t", " {\n\t\t\t\t\tpadding: 0 25px;\n\t\t\t\t}\n\t\t\t}\n\t\t"])), MainContent, baseMargin, ImageContainer, StyledCardboardCode.CardboardCodeRoot, Image, Sidebar, baseMargin, Sliders, AccordionList);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { StyledTextBox } from "@animech/ui";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { sidebarPadding } from "ui/components/panels/sidebar/sidebar.style";
export var DeveloperTicketText = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var DeveloperTicket = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme;
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\t\t\tposition: relative;\n\t\t\tbackground-color: white;\n\t\t\tcolor: black;\n\t\t\tpadding: ", "\n\t\t\t\t", ";\n\t\t\tmargin-top: -", ";\n\t\t\tmargin-bottom: ", ";\n\t\t\tdisplay: flex;\n\t\t\tjustify-content: space-between;\n\t\t\talign-items: center;\n\n\t\t\t", " {\n\t\t\t\theight: 28px;\n\t\t\t\twidth: 160px;\n\n\t\t\t\t", " {\n\t\t\t\t\talign-items: center;\n\t\t\t\t}\n\n\t\t\t\t", " {\n\t\t\t\t\tcolor: black !important;\n\t\t\t\t\tpadding: 0;\n\t\t\t\t}\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tfont-size: ", ";\n\t\t\t\tmargin-right: ", ";\n\t\t\t}\n\t\t"], ["\n\t\t\tposition: relative;\n\t\t\tbackground-color: white;\n\t\t\tcolor: black;\n\t\t\tpadding: ", "\n\t\t\t\t", ";\n\t\t\tmargin-top: -", ";\n\t\t\tmargin-bottom: ", ";\n\t\t\tdisplay: flex;\n\t\t\tjustify-content: space-between;\n\t\t\talign-items: center;\n\n\t\t\t", " {\n\t\t\t\theight: 28px;\n\t\t\t\twidth: 160px;\n\n\t\t\t\t", " {\n\t\t\t\t\talign-items: center;\n\t\t\t\t}\n\n\t\t\t\t", " {\n\t\t\t\t\tcolor: black !important;\n\t\t\t\t\tpadding: 0;\n\t\t\t\t}\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tfont-size: ", ";\n\t\t\t\tmargin-right: ", ";\n\t\t\t}\n\t\t"])), theme.dimensions.margin.xSmall, theme.dimensions.margin.small, sidebarPadding, sidebarPadding, StyledTextBox.Root, StyledTextBox.Container, StyledTextBox.Input, DeveloperTicketText, theme.typography.fontSize.small, theme.dimensions.margin.xSmall);
});
var templateObject_1, templateObject_2, templateObject_3;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { TooltipPortal } from "@animech/ui";
import { useEffect, useMemo, useRef, useState } from "react";
import * as Styled from "./text-box.style";
import { useShowInfo, } from "ui/components/elements/info-button";
import { EditParameterTooltip } from "ui/components/panels/canvas/overlay-elements/tooltip-components/edit-parameter-tooltip";
export var TextBoxWithEditableTooltip = function (props) {
    var parameter = props.parameter;
    var renderTooltip = function (anchorElement) { return (_jsx(EditParameterTooltip, { parameterNames: [parameter], anchorElement: anchorElement })); };
    return _jsx(TextBox, __assign({}, props, { renderTooltip: renderTooltip }));
};
export var TextBoxWithTooltip = function (props) {
    var tooltip = props.tooltip, description = props.description;
    var showInfo = useShowInfo(tooltip, description);
    var renderTooltip = function (anchorElement) { return (_jsx(TooltipPortal, { anchorElement: anchorElement, toggleEvent: tooltip ? "click" : undefined, placement: "top", children: _jsx(Styled.Tooltip, { color: "inverted", children: _jsx(Styled.TooltipContent, { onClick: showInfo, children: tooltip }) }) })); };
    return _jsx(TextBox, __assign({}, props, { renderTooltip: renderTooltip }));
};
export var TextBox = function (props) {
    var xRect = props.x, yRect = props.y, width = props.width, height = props.height, text = props.text, _a = props.color, color = _a === void 0 ? "#000000" : _a, renderTooltip = props.renderTooltip;
    var textElementRef = useRef(null);
    var _b = __read(useState(0), 2), textX = _b[0], setTextX = _b[1];
    var _c = __read(useState(0), 2), textY = _c[0], setTextY = _c[1];
    useEffect(function () {
        if (!textElementRef.current)
            return;
        var bbox = textElementRef.current.getBBox();
        var textWidth = bbox.width;
        var textHeight = bbox.height;
        // We calculate how much space will be left between the box and the text.
        // Then we use half of that length so that the padding on the left/right/top/bottom of the text will be equal
        var horizontalPAdding = (width - textWidth) / 2;
        setTextX(xRect + horizontalPAdding);
        var verticalPadding = (height - textHeight) / 2;
        setTextY(yRect + height - verticalPadding);
    }, [text, width, height, xRect, yRect]);
    var rectangle = useMemo(function () {
        var hoverStyle = {
            "&:hover": {
                cursor: "pointer",
                fillOpacity: "50%",
            },
        };
        return (_jsx("rect", { css: renderTooltip ? hoverStyle : {}, x: xRect, y: yRect, rx: 3, ry: 3, width: width, height: height, stroke: "#FFFFFF", fill: color }));
    }, [renderTooltip, xRect, yRect, width, height, color]);
    var textBox = useMemo(function () { return (_jsx("text", { ref: textElementRef, css: { pointerEvents: "none" }, x: textX, y: textY, fontSize: 12, fill: "#FFFFFF", alignmentBaseline: "text-after-edge", children: text })); }, [text, textX, textY]);
    return (_jsxs("g", { children: [renderTooltip ? renderTooltip(rectangle) : rectangle, textBox] }));
};

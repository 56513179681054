import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import React, { useMemo } from "react";
import * as Styled from "./dashboard-value.style";
import { round } from "utility/number";
import { useNumberConversion } from "hooks/number-conversion";
export var DashboardValue = function (props) {
    var name = props.name, value = props.value, unit = props.unit, _a = props.flexDirection, flexDirection = _a === void 0 ? "column" : _a, clickable = props.clickable, onClick = props.onClick;
    var convertNumber = useNumberConversion();
    var converted = useMemo(function () {
        if (unit) {
            var converted_1 = convertNumber(value !== null && value !== void 0 ? value : 0, unit);
            return {
                value: value === undefined ? "-" : converted_1.value,
                valueWithUnit: value === undefined ? "-" : converted_1.formatted,
                unit: converted_1.unit,
            };
        }
        else {
            var rounded = value === undefined ? "-" : round(value, 3);
            return { value: rounded, valueWithUnit: rounded };
        }
    }, [value, unit, convertNumber]);
    var LabelComponent = function () { return (_jsx(Styled.DashboardValueLabel, { size: "small", children: name ? name : converted.unit })); };
    var UnitComponent = function () { return (_jsx("div", { children: name ? converted.valueWithUnit : converted.value })); };
    return (_jsx(Styled.DashboardValue, { flexDirection: flexDirection, isClickable: clickable || !!onClick, children: _jsxs(_Fragment, { children: [_jsx(UnitComponent, {}), _jsx(LabelComponent, {})] }) }));
};

import { debounce } from "lodash";
import { useEffect, useMemo } from "react";
import { actions } from "./actions";
import { selectors } from "./selectors";
import { useAppDispatch, useAppSelector } from "./store";
var SANITY_CHECK_DEBOUNCE_TIME = 1000;
var FULL_CALC_WITH_ES_DEBOUNCE_TIME = 2000;
export function useCalculationObservers() {
    var dispatch = useAppDispatch();
    var preloadedPerformanceData = useAppSelector(selectors.selectPreloadedPerformance);
    var isSystemPerformanceLoading = useAppSelector(selectors.selectIsPerformanceLoading);
    var isSanityCheckLoading = useAppSelector(selectors.selectIsSanityCheckLoading);
    var isSanityCheckValid = useAppSelector(selectors.selectSanityCheckValid);
    var sanityCheckData = useAppSelector(selectors.selectSanityCheckData);
    var sanityCheckPassed = !isSanityCheckLoading &&
        (sanityCheckData === null || sanityCheckData === void 0 ? void 0 : sanityCheckData.validationPassed) &&
        isSanityCheckValid;
    //Get system performance data after sanity check has passed.
    useEffect(function () {
        if (!sanityCheckPassed)
            return;
        var promise = dispatch(actions.getSystemPerformance());
        return function () {
            promise === null || promise === void 0 ? void 0 : promise.abort();
        };
    }, [sanityCheckPassed, dispatch]);
    //Shows preloaded performance data when it has finished fetching it from the api, if performance data is currently loading.
    useEffect(function () {
        if (isSystemPerformanceLoading) {
            dispatch(actions.showPerformanceData());
        }
    }, [dispatch, isSystemPerformanceLoading, preloadedPerformanceData]);
    useTriggerSanityCheck();
    useTriggerCalculationWithEs();
}
//Trigger a debounced System Performance data with ES request when system performance request without ES is completed.
function useTriggerCalculationWithEs() {
    var dispatch = useAppDispatch();
    var isSystemPerformanceDataPending = useAppSelector(selectors.selectIsPerformanceDataPending);
    var preloadedPerformance = useAppSelector(selectors.selectPreloadedPerformance);
    var debouncedSystemPerformanceWithEs = useMemo(function () {
        return debounce(function () {
            return dispatch(actions.getSystemPerformanceWithEs());
        }, FULL_CALC_WITH_ES_DEBOUNCE_TIME);
    }, [dispatch]);
    useEffect(function () {
        if (isSystemPerformanceDataPending ||
            !(preloadedPerformance === null || preloadedPerformance === void 0 ? void 0 : preloadedPerformance.energySavingOff)) {
            return;
        }
        var promise = debouncedSystemPerformanceWithEs();
        return function () {
            promise === null || promise === void 0 ? void 0 : promise.abort();
        };
    }, [
        debouncedSystemPerformanceWithEs,
        dispatch,
        isSystemPerformanceDataPending,
        preloadedPerformance === null || preloadedPerformance === void 0 ? void 0 : preloadedPerformance.energySavingOff,
    ]);
}
// Trigger a new debounced Sanity Check calculation if any state related to calculations have been changed.
function useTriggerSanityCheck() {
    var dispatch = useAppDispatch();
    var nodes = useAppSelector(selectors.selectNodes);
    var edges = useAppSelector(selectors.selectEdges);
    var attachments = useAppSelector(selectors.selectAttachments);
    var manualCalculationInput = useAppSelector(selectors.selectManualCalculationInput);
    var systemDataDefineParameters = useAppSelector(selectors.selectSystemDataDefineParameters);
    var debouncedSanityCheck = useMemo(function () {
        return debounce(function () {
            return dispatch(actions.getSanityCheckData());
        }, SANITY_CHECK_DEBOUNCE_TIME);
    }, [dispatch]);
    useEffect(function () {
        if (!systemDataDefineParameters)
            return;
        dispatch(actions.setIsPerformanceDataValid(false));
        var promise = debouncedSanityCheck();
        return function () {
            promise === null || promise === void 0 ? void 0 : promise.abort();
        };
    }, [
        nodes,
        edges,
        attachments,
        systemDataDefineParameters,
        manualCalculationInput,
        dispatch,
        debouncedSanityCheck,
    ]);
}

import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useModal } from "@animech/ui";
import { useEffect } from "react";
import { useAppSelector, selectors } from "store";
import { NewsflashModal } from "ui/components/modals/newsflash";
export var useNewsFlashModal = function () {
    var openModal = useModal();
    var releaseNotes = useAppSelector(selectors.selectReleaseNotes);
    useEffect(function () {
        if (!releaseNotes) {
            return;
        }
        var shouldOpenNewsflash = localStorage.getItem("lastViewedNewsflashId") !== releaseNotes[0].id;
        if (shouldOpenNewsflash) {
            openModal(_jsx(NewsflashModal, { releaseNotes: releaseNotes }));
        }
    }, [releaseNotes, openModal]);
};

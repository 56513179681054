var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { StyledTooltip } from "@animech/ui";
import styled from "@emotion/styled";
import { CustomUITooltip } from "ui/components/elements/custom-ui-tooltip";
export var TooltipContent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tpadding: 4px 10px;\n\n\t&:hover {\n\t\tbackground-image: linear-gradient(rgb(0 0 0/10%) 0 0);\n\t\tcursor: pointer;\n\t}\n"], ["\n\tpadding: 4px 10px;\n\n\t&:hover {\n\t\tbackground-image: linear-gradient(rgb(0 0 0/10%) 0 0);\n\t\tcursor: pointer;\n\t}\n"])));
export var Tooltip = styled(CustomUITooltip)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\t", " {\n\t\tpadding: 0;\n\t}\n"], ["\n\t", " {\n\t\tpadding: 0;\n\t}\n"])), StyledTooltip.Content);
var templateObject_1, templateObject_2;

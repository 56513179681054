var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { addListener, createListenerMiddleware, } from "@reduxjs/toolkit";
import { generateListeners as generateChecklistListeners } from "./checklist-listeners";
import { actions } from "store/actions";
// Create the middleware instance and methods
export var listenerMiddleware = createListenerMiddleware();
// add listeners to update the current step in the other Redux slice
listenerMiddleware.startListening({
    actionCreator: actions.getSystemDataSelectApplication.fulfilled,
    effect: function (action, listenerApi) {
        if (action.payload.currentStep) {
            listenerApi.dispatch(actions.setSystemDataDefineCurrentStep(false));
        }
    },
});
listenerMiddleware.startListening({
    actionCreator: actions.getSystemDataDefineParameters.fulfilled,
    effect: function (action, listenerApi) {
        if (action.payload.currentStep) {
            listenerApi.dispatch(actions.setSystemDataSelectApplicationCurrentStep(false));
        }
    },
});
export var startAppListening = listenerMiddleware.startListening;
export var addAppListener = addListener;
var checklistListeners = generateChecklistListeners(startAppListening);
// Add listener functions to this array and they will be started automatically
var listeners = __spreadArray([], __read(checklistListeners), false);
// Start all listeners
listeners.forEach(function (startListenerFunction) { return startListenerFunction(); });

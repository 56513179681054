var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { createSelector } from "@reduxjs/toolkit";
import { systemDataConstants } from "api/system-data";
import { SpecialGroupLayout, } from "api/types";
import { isCentralizedSystem, getCupComponent, getVacuumPumpComponent, } from "store/system-data/helpers";
import { cardboardParameterNames } from "api/tacton/constants";
export var selectSystemDataRootDefineParameters = function (state) {
    return state.systemDataDefineParameters;
};
export var selectSystemDataDefineParameters = function (state) {
    return selectSystemDataRootDefineParameters(state).dataState.data;
};
export var selectIsSystemDataDefineParameterCurrentStep = function (state) { var _a; return !!((_a = selectSystemDataDefineParameters(state)) === null || _a === void 0 ? void 0 : _a.currentStep); };
export var selectIsLoadingDefineParameters = function (state) {
    return selectSystemDataRootDefineParameters(state).dataState.isLoading;
};
export var selectErrorsDefineParameters = function (state) {
    return selectSystemDataRootDefineParameters(state).dataState.errors;
};
export var selectCupSelectionMap = function (state) {
    return selectSystemDataRootDefineParameters(state).cupSelectionMap;
};
export var selectCupMountingSelectionMap = function (state) {
    return selectSystemDataRootDefineParameters(state).cupMountingSelectionMap;
};
export var selectPumpSelectionDecentralMap = function (state) {
    return selectSystemDataRootDefineParameters(state).pumpSelectionDecentralMap;
};
export var selectPumpSelectionCentralMap = function (state) {
    return selectSystemDataRootDefineParameters(state).pumpSelectionCentralMap;
};
export var selectPendingParameters = function (state) { var _a; return (_a = selectSystemDataRootDefineParameters(state).dataState.pending) === null || _a === void 0 ? void 0 : _a.parameters; };
export var selectPendingSelections = function (state) { var _a; return (_a = selectSystemDataRootDefineParameters(state).dataState.pending) === null || _a === void 0 ? void 0 : _a.selections; };
export var selectManualCalculationInput = function (state) {
    return state.systemDataDefineParameters.manualCalculationInput;
};
export var selectConflict = createSelector(selectSystemDataDefineParameters, function (systemData) { return systemData === null || systemData === void 0 ? void 0 : systemData.conflict; });
export var selectOutputDefineParameters = createSelector(selectSystemDataDefineParameters, function (systemData) { return systemData === null || systemData === void 0 ? void 0 : systemData.output; });
export var selectIsCentralizedSystem = createSelector(selectSystemDataDefineParameters, function (systemData) { return isCentralizedSystem(systemData); });
export var selectUseDoubleCompressedAirTubes = createSelector(selectSystemDataDefineParameters, function (systemData) {
    return systemData
        ? systemData.parameters[systemDataConstants.parameterNames.compressedAirConnections].value ===
            systemDataConstants.parameterValues.doubleCompressedAirConnections
        : false;
});
export var selectPartSecuredLevel = createSelector(selectSystemDataDefineParameters, function (systemData) {
    var _a;
    return Number((_a = systemData === null || systemData === void 0 ? void 0 : systemData.parameters[systemDataConstants.parameterNames.partSecuredLevel]) === null || _a === void 0 ? void 0 : _a.value) || 0;
});
export var selectSelectedCup = function (nodeId) {
    return createSelector(selectSystemDataDefineParameters, selectCupSelectionMap, function (systemData, cupSelectionMap) {
        return getCupComponent(nodeId, cupSelectionMap, systemData);
    });
};
export var selectSelectedCups = function (nodeIds) {
    return createSelector(selectSystemDataDefineParameters, selectCupSelectionMap, function (systemData, cupSelectionMap) {
        return nodeIds.map(function (nodeId) {
            return getCupComponent(nodeId, cupSelectionMap, systemData);
        });
    });
};
export var selectSelectedCupMounting = function (nodeId) {
    return createSelector(selectSystemDataDefineParameters, selectCupMountingSelectionMap, function (systemData, cupMountingSelectionMap) {
        if (!systemData)
            return;
        var selectionId = cupMountingSelectionMap[nodeId];
        if (!selectionId)
            return;
        var value = systemData.cupMountingSelections[selectionId];
        if (!value)
            return;
        return systemData.cupMountingComponents[value] || undefined;
    });
};
var selectedDecentralPumps = function (nodeIds, systemData, pumpSelectionDecentralMap) {
    if (!systemData || !systemData.vacuumPumpSelectionsDecentralized)
        return [];
    var selectionIds = nodeIds.map(function (nodeId) { return pumpSelectionDecentralMap[nodeId]; });
    var values = selectionIds.map(function (selectionId) { return systemData.vacuumPumpSelectionsDecentralized[selectionId]; });
    return values.map(function (value) { return (value && systemData.vacuumPumpComponents[value]) || undefined; });
};
export var selectSelectedDecentralPump = function (nodeId) {
    return createSelector(selectSystemDataDefineParameters, selectPumpSelectionDecentralMap, function (systemData, pumpSelectionDecentralMap) {
        var selected = selectedDecentralPumps([nodeId], systemData, pumpSelectionDecentralMap);
        return selected.length > 0 ? selected[0] : undefined;
    });
};
export var selectSelectedDecentralPumps = function (nodeIds) {
    return createSelector(selectSystemDataDefineParameters, selectPumpSelectionDecentralMap, function (systemData, pumpSelectionDecentralMap) {
        return selectedDecentralPumps(nodeIds, systemData, pumpSelectionDecentralMap);
    });
};
export var selectSelectedCentralPump = function (nodeId) {
    return createSelector(selectSystemDataDefineParameters, selectPumpSelectionCentralMap, function (systemData, pumpSelectionCentralMap) {
        if (!systemData)
            return;
        var selectionId = pumpSelectionCentralMap[nodeId];
        if (!selectionId)
            return;
        var value = systemData.vacuumPumpSelectionsCentralized[selectionId];
        if (!value)
            return;
        return systemData.vacuumPumpComponents[value] || undefined;
    });
};
export var selectSelectedCentralPumps = function (nodeIds) {
    return createSelector(selectSystemDataDefineParameters, selectPumpSelectionCentralMap, function (systemData, pumpSelectionCentralMap) {
        if (!systemData)
            return [];
        var result = nodeIds.map(function (nodeId) {
            var selectionId = pumpSelectionCentralMap[nodeId];
            if (!selectionId)
                return undefined;
            var value = systemData.vacuumPumpSelectionsCentralized[selectionId];
            if (!value)
                return undefined;
            return systemData.vacuumPumpComponents[value] || undefined;
        });
        return result;
    });
};
export var selectHasAvailableSelectionsForGripPosition = createSelector(selectSystemDataDefineParameters, selectCupSelectionMap, selectPumpSelectionDecentralMap, selectCupMountingSelectionMap, function (systemData, cupSelectionMap, pumpSelectionDecentralMap, cupMountingSelectionMap) {
    if (!systemData)
        return false;
    return (Object.keys(systemData.suctionCupSelections).some(function (selection) { return !Object.values(cupSelectionMap).includes(selection); }) &&
        Object.keys(systemData.vacuumPumpSelectionsDecentralized).some(function (selection) {
            return !Object.values(pumpSelectionDecentralMap).includes(selection);
        }) &&
        Object.keys(systemData.cupMountingSelections).some(function (selection) {
            return !Object.values(cupMountingSelectionMap).includes(selection);
        }));
});
export var selectHasAvailableSelectionsForPump = createSelector(selectSystemDataDefineParameters, selectPumpSelectionCentralMap, function (systemData, pumpSelectionCentralMap) {
    if (!systemData)
        return false;
    return Object.keys(systemData.vacuumPumpSelectionsCentralized).some(function (selection) {
        return !Object.values(pumpSelectionCentralMap).includes(selection);
    });
});
export var selectHasCupSelection = createSelector(selectSystemDataDefineParameters, selectCupSelectionMap, function (systemData, cupSelectionMap) {
    if (!systemData)
        return false;
    return Object.values(cupSelectionMap).some(function (selection) { return systemData.suctionCupSelections[selection]; });
});
export var selectHasPumpSelection = createSelector(selectSystemDataDefineParameters, selectPumpSelectionCentralMap, selectPumpSelectionDecentralMap, function (systemData, pumpSelectionCentralMap, pumpSelectionDecentralMap) {
    if (!systemData)
        return false;
    return (Object.values(pumpSelectionCentralMap).some(function (selection) { return systemData.vacuumPumpSelectionsCentralized[selection]; }) ||
        Object.values(pumpSelectionDecentralMap).some(function (selection) { return systemData.vacuumPumpSelectionsDecentralized[selection]; }));
});
export var selectGroupedParametersDefineParameters = createSelector(selectSystemDataDefineParameters, function (systemData) {
    if (!systemData)
        return [];
    return Object.values(systemData.parameterGroups).map(function (group) {
        return __assign(__assign({}, group), { parameters: Object.values(systemData.parameters).filter(function (parameter) { return parameter.groupId === group.id; }) });
    });
});
export var selectParametersGroupedInSubGroups = function (parentGroupId) {
    return createSelector(selectSystemDataDefineParameters, selectGroupedParametersDefineParameters, function (systemData, groups) {
        var e_1, _a;
        var group = groups.find(function (group) { return group.id === parentGroupId; });
        if (!group || !group.parameters) {
            return [];
        }
        var subGroups = systemData ? systemData.parameterSubGroups : [];
        var parametersAndGroups = [];
        var _loop_1 = function (param) {
            var belongsToSubGroup = subGroups.find(function (subGroup) {
                return subGroup.memberIds.includes(param.id);
            });
            if (!belongsToSubGroup) {
                parametersAndGroups.push(param);
                return "continue";
            }
            var existingSubGroup = parametersAndGroups.find(function (parameterOrGroup) { return parameterOrGroup.id === belongsToSubGroup.id; });
            if (existingSubGroup) {
                existingSubGroup.parameters.push(param);
            }
            else {
                var newSubGroup = {
                    id: belongsToSubGroup.id,
                    parameters: [param],
                    description: belongsToSubGroup.description,
                    properties: belongsToSubGroup.properties,
                };
                parametersAndGroups.push(newSubGroup);
            }
        };
        try {
            for (var _b = __values(group.parameters), _c = _b.next(); !_c.done; _c = _b.next()) {
                var param = _c.value;
                _loop_1(param);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return parametersAndGroups;
    });
};
export var selectIsMultiCircuitSystem = createSelector(selectIsCentralizedSystem, selectPumpSelectionCentralMap, function (isCentralizedSystem, pumpSelectionCentralMap) {
    if (!isCentralizedSystem)
        return false;
    return Object.values(pumpSelectionCentralMap).length > 1;
});
export var selectLatestSelectedCup = function (state) {
    return selectSystemDataRootDefineParameters(state).latestSelectedCup;
};
export var selectVacuumPumpComponent = function (nodeId) {
    return createSelector(selectSystemDataDefineParameters, selectPumpSelectionCentralMap, selectPumpSelectionDecentralMap, function (systemData, pumpSelectionCentralMap, pumpSelectionDecentralMap) {
        return getVacuumPumpComponent(nodeId, pumpSelectionCentralMap, pumpSelectionDecentralMap, systemData);
    });
};
/* ------------------------------------------ *
 * ------ CARDBOARD Specific Selectors ------ *
 * ------------------------------------------ */
//#region Cardboard
export var selectGroupedParametersDefineCardboard = createSelector(selectGroupedParametersDefineParameters, function (groupedParameters) {
    return groupedParameters.find(function (group) { return group.specialGroupLayout === SpecialGroupLayout.CARDBOARD; });
});
export var selectPaperCoatingValue = createSelector(selectSystemDataDefineParameters, function (systemData) {
    var _a;
    if (!systemData)
        return;
    return (_a = systemData.parameters[cardboardParameterNames.paperCoating]) === null || _a === void 0 ? void 0 : _a.value;
});
export var selectTapeParameter = createSelector(selectSystemDataDefineParameters, function (systemData) {
    var _a;
    if (!systemData)
        return;
    return (_a = systemData.parameters[cardboardParameterNames.cardboardTape]) === null || _a === void 0 ? void 0 : _a.value;
});
export var selectTapeEndsParameter = createSelector(selectSystemDataDefineParameters, function (systemData) {
    if (!systemData)
        return;
    return systemData.parameters[cardboardParameterNames.cardboardTapeEnds];
});
export var selectCardboardCodeParameter = createSelector(selectSystemDataDefineParameters, function (systemData) {
    if (!systemData)
        return;
    return systemData.parameters[cardboardParameterNames.cardboardCode];
});
export var selectHasStartedBuildingSystem = createSelector(selectPendingSelections, selectSystemDataDefineParameters, function (pendingSelections, systemData) {
    var hasPendingSelections = !!pendingSelections && Object.values(pendingSelections).length > 0;
    var selections = [
        systemData === null || systemData === void 0 ? void 0 : systemData.suctionCupSelections,
        systemData === null || systemData === void 0 ? void 0 : systemData.vacuumPumpSelectionsCentralized,
        systemData === null || systemData === void 0 ? void 0 : systemData.vacuumPumpSelectionsDecentralized,
        systemData === null || systemData === void 0 ? void 0 : systemData.cupMountingSelections,
    ];
    var hasSelections = selections
        .flatMap(function (selection) { return (selection ? Object.values(selection) : []); })
        .some(function (value) { return value !== null; });
    return hasPendingSelections || hasSelections;
});

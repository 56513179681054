import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { H3, H5 } from "@animech/ui";
import * as Styled from "./summary-pdf.style";
import { Logo } from "ui/components/elements/logo";
import * as Table from "ui/components/elements/table";
import { useNumberConversion } from "hooks/number-conversion";
import { Unit, round } from "utility/number";
import { useAppSelector, selectors } from "store";
export var SummaryPDF = forwardRef(function (_a, ref) {
    var screenshotSrc = _a.screenshotSrc, graphScreenshotSrc = _a.graphScreenshotSrc, summaryTable = _a.summaryTable;
    var dashboardData = useAppSelector(selectors.selectDashboardData);
    var systemVolume = useAppSelector(selectors.selectVacuumSystemVolume);
    var convertNumber = useNumberConversion();
    var t = useTranslation().t;
    var airConsumptionPerCycle = dashboardData &&
        convertNumber(dashboardData.airConsumption.perCycle.value, Unit.NL_CYCLE);
    var airConsumptionPerMinute = dashboardData &&
        convertNumber(dashboardData.airConsumption.perMinute.value, Unit.NL_MIN);
    return (_jsxs(Styled.SummaryPDF, { ref: ref, children: [_jsxs("div", { className: "".concat(Styled.prefix, "-title"), children: [_jsx(Logo, { isInverted: true }), _jsxs(H3, { children: [" ", t("summary_pdf.title"), " "] })] }), _jsx("img", { src: screenshotSrc }), _jsx("img", { src: graphScreenshotSrc }), _jsx(Styled.PageBreak, {}), _jsx("div", { className: "".concat(Styled.prefix, "-section-title"), children: _jsxs(H5, { children: [" ", t("summary_pdf.components_title"), " "] }) }), summaryTable, _jsx(Styled.PageBreak, {}), _jsx("div", { className: "".concat(Styled.prefix, "-section-title"), children: _jsxs(H5, { children: [" ", t("summary_pdf.performance_title"), " "] }) }), dashboardData && (_jsxs(_Fragment, { children: [_jsx("div", { className: "".concat(Styled.prefix, "-performance-table"), children: _jsx(Table.Root, { children: _jsxs(Table.Body, { children: [_jsx(Table.HeaderRow, { children: _jsx(Table.HeaderCol, { children: t("dashboard.application_speed") }) }), dashboardData.applicationSpeed &&
                                        Object.values(dashboardData.applicationSpeed).map(function (field) { return (_jsxs(Table.Row, { children: [_jsxs(Table.Col, { children: [" ", t(field.translationKey), " "] }), _jsx(Table.Col, { children: round(field.value, 3) })] }, field.id)); }), _jsxs(Table.Row, { children: [_jsx(Table.Col, { children: t(dashboardData.targetPartsPerMinute.translationKey) }), _jsx(Table.Col, { children: dashboardData.targetPartsPerMinute.value })] }), _jsxs(Table.Row, { children: [_jsx(Table.Col, { children: t(dashboardData.targetSecsPerCycle.translationKey) }), _jsx(Table.Col, { children: dashboardData.targetSecsPerCycle.value })] })] }) }) }), _jsx("div", { className: "".concat(Styled.prefix, "-performance-table"), children: _jsx(Table.Root, { children: _jsxs(Table.Body, { children: [_jsx(Table.HeaderRow, { children: _jsx(Table.HeaderCol, { children: t("dashboard.vacuum_levels") }) }), _jsxs(Table.Row, { children: [_jsx(Table.Col, { children: t(dashboardData.desiredVacuumLevels.partSecured
                                                    .translationKey) }), _jsx(Table.Col, { children: convertNumber(dashboardData.desiredVacuumLevels.partSecured.value, Unit.KPA).formatted })] }), dashboardData.desiredVacuumLevels.energySaving && (_jsxs(Table.Row, { children: [_jsx(Table.Col, { children: t(dashboardData.desiredVacuumLevels.energySaving
                                                    .translationKey) }), _jsx(Table.Col, { children: convertNumber(dashboardData.desiredVacuumLevels.energySaving
                                                    .value, Unit.KPA).formatted })] }))] }) }) }), _jsx("div", { className: "".concat(Styled.prefix, "-performance-table"), children: _jsx(Table.Root, { children: _jsxs(Table.Body, { children: [_jsx(Table.HeaderRow, { children: _jsx(Table.HeaderCol, { children: t("dashboard.air_consumption") }) }), _jsxs(Table.Row, { children: [_jsx(Table.Col, { children: airConsumptionPerCycle === null || airConsumptionPerCycle === void 0 ? void 0 : airConsumptionPerCycle.unit }), _jsx(Table.Col, { children: airConsumptionPerCycle === null || airConsumptionPerCycle === void 0 ? void 0 : airConsumptionPerCycle.value })] }), _jsxs(Table.Row, { children: [_jsx(Table.Col, { children: airConsumptionPerMinute === null || airConsumptionPerMinute === void 0 ? void 0 : airConsumptionPerMinute.unit }), _jsx(Table.Col, { children: airConsumptionPerMinute === null || airConsumptionPerMinute === void 0 ? void 0 : airConsumptionPerMinute.value })] }), _jsxs(Table.Row, { children: [_jsx(Table.Col, { children: t("dashboard.system_volume") }), _jsx(Table.Col, { children: convertNumber(systemVolume, Unit.L).formatted })] })] }) }) }), _jsx("div", { className: "".concat(Styled.prefix, "-performance-table"), children: _jsx(Table.Root, { children: _jsxs(Table.Body, { children: [_jsx(Table.HeaderRow, { children: _jsx(Table.HeaderCol, { children: t("dashboard.power_consumption") }) }), dashboardData.energyUsage &&
                                        Object.values(dashboardData.energyUsage).map(function (field) {
                                            var convertedValue = convertNumber(field.value, field.unit);
                                            return (_jsxs(Table.Row, { children: [_jsxs(Table.Col, { children: [" ", t(field.translationKey), " "] }), _jsx(Table.Col, { children: convertedValue.formatted })] }, field.translationKey));
                                        })] }) }) })] }))] }));
});
SummaryPDF.displayName = "SummaryPDF";

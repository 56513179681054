import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { useLocation } from "react-router-dom";
import * as Styled from "./main.style";
import { SidebarPanel } from "ui/components/panels/sidebar";
import { TopbarPanel } from "ui/components/panels/topbar";
import { DashboardPanel } from "ui/components/panels/dashboard";
import { CanvasPanel } from "ui/components/panels/canvas";
import { NotificationPanel } from "ui/components/panels/notification";
import { useConflictHandler } from "hooks/conflict-handler";
import { GraphDataProvider } from "providers/graph-panel";
import { Checklist } from "ui/components/panels/checklist";
import { ComponentDataPanel } from "ui/components/panels/dashboard/component-data-list";
import { HomeMenu } from "ui/components/panels/home-menu/home-menu";
import { PageRoute } from "utility/url";
import { useLoadReleaseNotes } from "hooks/release-notes";
export var MainPage = function () {
    var currentPath = useLocation().pathname;
    var isSystemDesignPage = currentPath === PageRoute.SYSTEM_DESIGN;
    useConflictHandler();
    useLoadReleaseNotes();
    return (_jsxs(_Fragment, { children: [!isSystemDesignPage && _jsx(HomeMenu, {}), _jsx(Styled.Scene, { children: _jsxs(GraphDataProvider, { children: [_jsx(SidebarPanel, {}), _jsxs(Styled.MainContent, { children: [_jsx(CanvasPanel, {}), _jsxs(Styled.CanvasUIOverlay, { children: [_jsx(TopbarPanel, {}), _jsx(NotificationPanel, {}), _jsx(Checklist, {}), _jsx(ComponentDataPanel, {}), _jsx(DashboardPanel, {})] })] })] }) })] }));
};

import { createMigrate } from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import { transform } from "./persist-transform";
import { migrations, } from "store/system-data/persist-migrations";
import { currentSystemDataVersion, } from "store/types";
var persistKey = "systemDataDefineParameters";
export var persistConfig = {
    version: currentSystemDataVersion,
    storage: storage,
    transforms: [transform],
    key: persistKey,
    migrate: createMigrate(migrations),
};

var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { TreeNavItem, Button, useModal } from "@animech/ui";
import * as Styled from "./sidebar.style";
import { GripPositionMenu, TubeConnectionMenu, CentralizedVacuumPumpMenu, ApplicationSpeedMenu, EdgeMenu, FeedPressurePointMenu, } from "./tree-nav-menus";
import { MixedSelectionMenu } from "./tree-nav-menus/mixed-selection-menu";
import { DefaultGroupMenu } from "./tree-nav-menus/default-group-menu";
import { ApplicationMenu } from "./tree-nav-menus/application-menu";
import { usePlaycanvasApplicationContext } from "providers/playcanvas-application";
import { Logo } from "ui/components/elements/logo";
import { CustomTreeNav } from "ui/components/elements/custom-treenav";
import { TimedNotification } from "ui/components/elements/timed-notification";
import { DelayedFallback } from "ui/utility/delayed-fallback";
import { LoadingOverlay } from "ui/components/elements/loading-overlay";
import { SummaryModal } from "ui/components/modals/summary";
import { Toolbar } from "ui/components/elements/toolbar";
import { useAppSelector, useAppDispatch, selectors, actions } from "store";
import { MenuItem, NodeComponentType } from "store/types";
import { getSharableLink } from "utility/share";
import { useGraphDataContext } from "providers/graph-panel";
import { TitleWithInfoButton } from "ui/components/panels/sidebar/title-with-info-button";
import { SidebarDrawerButton } from "ui/components/elements/custom-drawer";
import { DeveloperTicket } from "ui/components/elements/ticket/developer-ticket";
import { SystemTypeSwitch } from "ui/components/panels/sidebar/system-type-switch";
export var SidebarPanel = function () {
    var _a;
    var _b = __read(useState(false), 2), showShareNotification = _b[0], setShowShareNotification = _b[1];
    var openModal = useModal();
    var drawerRef = useRef(null);
    var t = useTranslation().t;
    var activeMenuItem = useAppSelector(selectors.selectActiveMenuItem);
    var selectedIds = useAppSelector(selectors.selectSelectedIds);
    var edges = useAppSelector(selectors.selectEdges);
    var selectedEdges = useAppSelector(selectors.selectSelectedEdges);
    var is3DLoading = useAppSelector(selectors.selectIs3DLoading);
    var isSystemDataLoading = useAppSelector(selectors.selectIsSystemDataLoading);
    var selectedNodes = useAppSelector(selectors.selectSelectedNodes);
    var selectedNodesType = useAppSelector(selectors.selectSelectedNodesType);
    var dispatch = useAppDispatch();
    var generateSceneScreenshot = usePlaycanvasApplicationContext().generateScreenshot;
    var _c = useGraphDataContext(), generateGraphScreenshot = _c.generateScreenshot, setPrintModeActive = _c.setPrintModeActive;
    var parameterGroups = useAppSelector(selectors.selectGroupedParametersDefineParameters);
    var onGenerateSceneScreenshot = function (width, height) {
        return generateSceneScreenshot(width, height);
    };
    var toggleGraphPanelInPrintMode = function (active) {
        dispatch(actions.toggleGraphPanel(active));
        setPrintModeActive(active);
    };
    var onOpenSummaryModal = function () {
        /* Deselect entities before showing modal to avoid faded entities
         * in the screenshot. Could probably be improved in the future */
        if (selectedIds.length > 0) {
            dispatch(actions.deselectEntities);
        }
        toggleGraphPanelInPrintMode(true);
        openModal(_jsx(SummaryModal, { generateSceneScreenshot: onGenerateSceneScreenshot, generateGraphScreenshot: generateGraphScreenshot, onCloseModal: function () { return toggleGraphPanelInPrintMode(false); } }));
    };
    var onCopyLink = function () {
        navigator.clipboard.writeText(getSharableLink());
        setShowShareNotification(true);
    };
    var getSelectedItemMenuTitle = function () {
        if (selectedIds.length === 0 ||
            activeMenuItem !== MenuItem.SELECTED_ENTITY) {
            return "";
        }
        if (selectedNodes.length === 0) {
            return _jsx(TitleWithInfoButton, { title: t("sidebar.edit_tube.title") });
        }
        if (selectedNodesType === null) {
            return _jsx(TitleWithInfoButton, { title: t("sidebar.mixed_selection") });
        }
        switch (selectedNodesType) {
            case NodeComponentType.GRIP_POSITION:
                return (_jsx(TitleWithInfoButton, { title: t("sidebar.edit_grip_position.title") }));
            case NodeComponentType.TUBE_CONNECTION:
                return (_jsx(TitleWithInfoButton, { title: t("sidebar.edit_tube_connection.title"), description: {
                        long: t("descriptions.sidebar.edit_tube_connection.title"),
                    } }));
            case NodeComponentType.CENTRALIZED_VACUUM_PUMP: {
                return _jsx(TitleWithInfoButton, { title: t("sidebar.edit_pump.title") });
            }
            case NodeComponentType.FEED_PRESSURE_POINT:
                return (_jsx(TitleWithInfoButton, { title: t("sidebar.edit_feed_pressure_point.title") }));
            default:
                return "";
        }
    };
    var getSelectedItemComponent = function () {
        if (activeMenuItem !== MenuItem.SELECTED_ENTITY)
            return null;
        if (selectedIds.length === 0)
            return null;
        // both nodes and edges are selected
        if (selectedNodes.length && selectedEdges.length) {
            return _jsx(MixedSelectionMenu, { nodes: selectedNodes, edges: selectedEdges });
        }
        // only edges are selected
        if (selectedIds.every(function (id) { return edges[id]; })) {
            return _jsx(EdgeMenu, { edges: selectedEdges });
        }
        // only nodes are selected, check if they are of the same type
        switch (selectedNodesType) {
            case NodeComponentType.GRIP_POSITION:
                return _jsx(GripPositionMenu, { nodes: selectedNodes });
            case NodeComponentType.TUBE_CONNECTION:
                return _jsx(TubeConnectionMenu, { nodes: selectedNodes });
            case NodeComponentType.CENTRALIZED_VACUUM_PUMP: {
                return _jsx(CentralizedVacuumPumpMenu, { nodes: selectedNodes });
            }
            case NodeComponentType.FEED_PRESSURE_POINT: {
                // Assumption: only one feed pressure point can exist in a system
                return _jsx(FeedPressurePointMenu, { node: selectedNodes[0] });
            }
            default: {
                return (_jsx(MixedSelectionMenu, { nodes: selectedNodes, edges: selectedEdges }));
            }
        }
    };
    var sidebarWidth = (_a = drawerRef.current) === null || _a === void 0 ? void 0 : _a.clientWidth;
    return (_jsxs(Styled.SidebarPanel, { ref: drawerRef, children: [_jsx(DeveloperTicket, {}), _jsx(Styled.LogoContainer, { children: _jsx(Logo, {}) }), _jsx(SystemTypeSwitch, {}), _jsx(Toolbar, {}), _jsxs(CustomTreeNav, { label: t("sidebar.title"), className: "".concat(Styled.prefix, "-top-tree-nav"), activeItem: activeMenuItem, onActiveItemChange: function (itemId) {
                    return dispatch(actions.openMenuItem(itemId));
                }, children: [_jsx(DefaultGroupMenu, { sidebarWidth: sidebarWidth, parameterGroups: parameterGroups, activeMenuItem: activeMenuItem }), _jsx(SidebarDrawerButton, { sidebarWidth: sidebarWidth ? sidebarWidth : 0, title: t("sidebar.application"), id: "application", button: _jsx(TreeNavItem, { id: "application", label: t("sidebar.application") }), customRenderElement: _jsx(ApplicationMenu, {}) }), _jsx(TreeNavItem, { className: "".concat(Styled.prefix, "-hidden-tree-nav-item"), id: "application_speed", label: t("dashboard.application_speed"), children: _jsx(ApplicationSpeedMenu, {}) }), _jsx(TreeNavItem, { className: "".concat(Styled.prefix, "-hidden-tree-nav-item"), id: MenuItem.SELECTED_ENTITY, label: getSelectedItemMenuTitle(), children: getSelectedItemComponent() })] }), _jsx(Button, { className: "".concat(Styled.prefix, "-sidebar-button"), onClick: onOpenSummaryModal, children: t("sidebar.summary_button") }), _jsx(Button, { className: "".concat(Styled.prefix, "-sidebar-button"), appearance: "inverted", onClick: onCopyLink, icon: "copy", children: t("sidebar.share_button") }), showShareNotification && (_jsx(TimedNotification, { text: t("notifications.link_copied"), timeout: 3000, color: "success", onClose: function () { return setShowShareNotification(false); } })), _jsx(DelayedFallback, { isLoading: isSystemDataLoading || is3DLoading, fallback: _jsx(LoadingOverlay, {}), delay: 200 })] }));
};

import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Trans, useTranslation } from "react-i18next";
import * as Styled from "./welcome-screen.style";
import { TabHeader, TabSubHeader, } from "ui/components/panels/home-menu/tabs/tab-header";
export var Welcome = function () {
    var t = useTranslation().t;
    var translationKey = function (suffix) {
        return "home-menu.welcome-screen.".concat(suffix);
    };
    return (_jsxs(Styled.WelcomeScreen, { children: [_jsx(TabHeader, { header: t(translationKey("header")) }), _jsx(TabSubHeader, { header: t(translationKey("sub-header")) }), _jsxs(Styled.WelcomeScreenText, { children: [_jsx("p", { children: t(translationKey("tool-description")) }), _jsx("p", { children: t(translationKey("how-to-start")) }), _jsx("p", { children: _jsxs(Trans, { i18nKey: translationKey("how-to-buy"), children: ["text before link", _jsx("a", { href: "https://www.piab.com", target: "_blank", rel: "noreferrer noopener", children: "link" }), "text after link"] }) }), _jsx("p", { children: t(translationKey("thank-you")) })] }), _jsx(Styled.Disclaimer, { children: t(translationKey("disclaimer")) })] }));
};

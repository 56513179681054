import { actions } from "store/actions";
import { ChecklistItemName, NodeComponentType } from "store/types";
import { isCentralizedSystem } from "store/system-data/helpers";
import { selectGripPositionMissingComponent } from "store/scene/selectors";
var getPresentSceneState = function (currentState) {
    return currentState.scene.present;
};
var isSelected = function (selections) {
    var isSelected = selections &&
        Object.values(selections).some(function (selection) { return selection !== null; });
    return !!isSelected;
};
// Ignore the action of this listener effect to avoid infinite loop.
var isActionTypeCompleteChecklistItem = function (action) {
    var setChecklistCompleteActionName = "setChecklistItemCompleted";
    return action.type === "ui/".concat(setChecklistCompleteActionName);
};
// Ignore the action of this listener effect to avoid infinite loop.
var isActionTypeHideChecklistItem = function (action) {
    var setChecklistHiddenActionName = "setChecklistItemHidden";
    return action.type === "ui/".concat(setChecklistHiddenActionName);
};
// We don't want to trigger actions unecessarily, so if the checklist item is already completed we can skip the trigger action.
var isChecklistItemCompleted = function (currentState, checklistItemName) {
    var checklist = currentState.ui.checklist;
    var isItemComplete = checklist[checklistItemName].completed;
    return isItemComplete;
};
// We don't want to trigger actions unecessarily, so if the checklist item is already hidden we can skip the trigger action.
var isChecklistItemHidden = function (currentState, checklistItemName) {
    var checklist = currentState.ui.checklist;
    var isItemHidden = checklist[checklistItemName].hidden;
    return isItemHidden;
};
export var generateListeners = function (startAppListening) {
    var defaultCompleteStepListener = function (shouldTrigger, checklistItemName) {
        var action = actions.setChecklistItemCompleted({
            itemName: checklistItemName,
            completed: true,
        });
        defaultListener(shouldTrigger, checklistItemName, action, false);
    };
    var defaultUnCompleteStepListener = function (shouldTrigger, checklistItemName) {
        var action = actions.setChecklistItemCompleted({
            itemName: checklistItemName,
            completed: false,
        });
        defaultListener(shouldTrigger, checklistItemName, action, true);
    };
    var defaultListener = function (shouldTrigger, checklistItemName, triggerAction, shouldUncomplete) {
        startAppListening({
            predicate: function (action, currentState) {
                if (isActionTypeCompleteChecklistItem(action)) {
                    return false;
                }
                var isItemCompleted = isChecklistItemCompleted(currentState, checklistItemName);
                if (shouldUncomplete && !isItemCompleted) {
                    return false;
                }
                if (!shouldUncomplete && isItemCompleted) {
                    return false;
                }
                return shouldTrigger({ currentState: currentState });
            },
            effect: function (_action, _a) {
                var dispatch = _a.dispatch;
                dispatch(triggerAction);
            },
        });
    };
    var isGripPositionAdded = function (currentState) {
        var sceneNodes = Object.values(getPresentSceneState(currentState).nodes);
        var gripPositionExistsInScene = sceneNodes.some(function (node) { return node.type === NodeComponentType.GRIP_POSITION; });
        return gripPositionExistsInScene;
    };
    var completeGripPosition = function () {
        var shouldTrigger = function (_a) {
            var currentState = _a.currentState;
            return isGripPositionAdded(currentState);
        };
        defaultCompleteStepListener(shouldTrigger, ChecklistItemName.ADD_GRIP_POSITION);
    };
    var unCompleteGripPosition = function () {
        var shouldTrigger = function (_a) {
            var currentState = _a.currentState;
            return !isGripPositionAdded(currentState);
        };
        defaultUnCompleteStepListener(shouldTrigger, ChecklistItemName.ADD_GRIP_POSITION);
    };
    var isCupSelected = function (currentState) {
        var _a;
        var cupSelections = (_a = currentState.systemDataDefineParameters.dataState.data) === null || _a === void 0 ? void 0 : _a.suctionCupSelections;
        return isSelected(cupSelections);
    };
    var completeSelectCup = function () {
        var shouldTrigger = function (_a) {
            var currentState = _a.currentState;
            return isCupSelected(currentState);
        };
        defaultCompleteStepListener(shouldTrigger, ChecklistItemName.SELECT_CUP);
    };
    var unCompleteSelectCup = function () {
        var shouldTrigger = function (_a) {
            var currentState = _a.currentState;
            return !isCupSelected(currentState);
        };
        defaultUnCompleteStepListener(shouldTrigger, ChecklistItemName.SELECT_CUP);
    };
    var isPumpAdded = function (currentState) {
        var _a, _b;
        var isCentralized = isCentralizedSystem(currentState.systemDataDefineParameters.dataState.data);
        if (isCentralized) {
            var centralPumpSelections = (_a = currentState.systemDataDefineParameters.dataState.data) === null || _a === void 0 ? void 0 : _a.vacuumPumpSelectionsCentralized;
            return isSelected(centralPumpSelections);
        }
        else {
            var decentralPumpSelections = (_b = currentState.systemDataDefineParameters.dataState.data) === null || _b === void 0 ? void 0 : _b.vacuumPumpSelectionsDecentralized;
            return isSelected(decentralPumpSelections);
        }
    };
    var completeAddPump = function () {
        var shouldTrigger = function (_a) {
            var currentState = _a.currentState;
            return isPumpAdded(currentState);
        };
        defaultCompleteStepListener(shouldTrigger, ChecklistItemName.ADD_PUMP);
    };
    var unCompleteAddPump = function () {
        var shouldTrigger = function (_a) {
            var currentState = _a.currentState;
            return !isPumpAdded(currentState);
        };
        defaultUnCompleteStepListener(shouldTrigger, ChecklistItemName.ADD_PUMP);
    };
    var hasDisconnectedParts = function (currentState) {
        var hasDisconnectedParts = Object.values(getPresentSceneState(currentState).nodes).some(function (node) {
            var isCentralized = isCentralizedSystem(currentState.systemDataDefineParameters.dataState.data);
            // Centralized pumps sometimes still remain in the state even when system is decentralized.
            // So we don't need to check if those components are connected for decentralized systems.
            if (!isCentralized &&
                node.type === NodeComponentType.CENTRALIZED_VACUUM_PUMP) {
                return false;
            }
            // we ignore grip positions that are missing components (but we will show a warning when trying to calculate the system performance)
            if (selectGripPositionMissingComponent(node)(currentState)) {
                return false;
            }
            return node.attachmentIds.every(function (attachmentId) {
                var attachment = getPresentSceneState(currentState).attachments[attachmentId];
                return attachment.edgeId === undefined;
            });
        });
        return (getPresentSceneState(currentState).hasDisconnectedEdges ||
            hasDisconnectedParts);
    };
    var completeConnectParts = function () {
        var shouldTrigger = function (_a) {
            var currentState = _a.currentState;
            return !hasDisconnectedParts(currentState);
        };
        defaultCompleteStepListener(shouldTrigger, ChecklistItemName.CONNECT_PARTS);
    };
    var unCompleteConnectParts = function () {
        var shouldTrigger = function (_a) {
            var currentState = _a.currentState;
            return hasDisconnectedParts(currentState);
        };
        defaultUnCompleteStepListener(shouldTrigger, ChecklistItemName.CONNECT_PARTS);
    };
    var tubeConnectionExistsInScene = function (currentState) {
        var sceneNodes = Object.values(getPresentSceneState(currentState).nodes);
        var tubeConnectionExistsInScene = sceneNodes.some(function (node) { return node.type === NodeComponentType.TUBE_CONNECTION; });
        return tubeConnectionExistsInScene;
    };
    var completeAddTubeConnection = function () {
        var shouldTrigger = function (_a) {
            var currentState = _a.currentState;
            return tubeConnectionExistsInScene(currentState);
        };
        defaultCompleteStepListener(shouldTrigger, ChecklistItemName.ADD_TUBE_CONNECTION);
    };
    var unCompleteAddTubeConnection = function () {
        var shouldTrigger = function (_a) {
            var currentState = _a.currentState;
            return !tubeConnectionExistsInScene(currentState);
        };
        defaultUnCompleteStepListener(shouldTrigger, ChecklistItemName.ADD_TUBE_CONNECTION);
    };
    var completeCalculate = function () {
        startAppListening({
            actionCreator: actions.showPerformanceData,
            effect: function (_action, _a) {
                var dispatch = _a.dispatch;
                dispatch(actions.setChecklistItemCompleted({
                    itemName: ChecklistItemName.CALCULATE,
                    completed: true,
                }));
            },
        });
    };
    var unCompleteCalculate = function () {
        var shouldTrigger = function (_a) {
            var currentState = _a.currentState;
            var isSystemChangedSinceLastCalculate = !currentState.systemPerformance.isPerformanceDataValid;
            return isSystemChangedSinceLastCalculate;
        };
        defaultUnCompleteStepListener(shouldTrigger, ChecklistItemName.CALCULATE);
    };
    /* Adding a Tube connection is not always necessary to complete a system. Therefore we
     * will hide the "Add Tube Connection" checklist step if the user doesn't add one and calculates the system. */
    var hideAddTubeIfCalculated = function () {
        startAppListening({
            predicate: function (action, currentState) {
                if (isActionTypeHideChecklistItem(action)) {
                    return false;
                }
                var isItemHidden = isChecklistItemHidden(currentState, ChecklistItemName.ADD_TUBE_CONNECTION);
                if (isItemHidden) {
                    return false;
                }
                var isCalculationStepCompleted = currentState.ui.checklist[ChecklistItemName.CALCULATE].completed;
                var isAddTubeStepCompleted = currentState.ui.checklist[ChecklistItemName.ADD_TUBE_CONNECTION]
                    .completed;
                var shouldTrigger = isCalculationStepCompleted && !isAddTubeStepCompleted;
                return shouldTrigger;
            },
            effect: function (_action, _a) {
                var dispatch = _a.dispatch;
                var action = actions.setChecklistItemHidden({
                    itemName: ChecklistItemName.ADD_TUBE_CONNECTION,
                    hidden: true,
                });
                dispatch(action);
            },
        });
    };
    return [
        completeGripPosition,
        unCompleteGripPosition,
        completeSelectCup,
        unCompleteSelectCup,
        completeAddPump,
        unCompleteAddPump,
        completeAddTubeConnection,
        unCompleteAddTubeConnection,
        completeCalculate,
        unCompleteCalculate,
        hideAddTubeIfCalculated,
        completeConnectParts,
        unCompleteConnectParts,
    ];
};

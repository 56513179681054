import { jsx as _jsx, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { TreeNavItem } from "@animech/ui";
import { ObjectParameterMenu } from "./object-parameter-menu";
import { SpecialGroupLayout } from "api/types";
import { SidebarDrawerButton } from "ui/components/elements/custom-drawer";
import { TitleWithInfoButton } from "ui/components/panels/sidebar/title-with-info-button";
import { DefineCardboardContainer } from "ui/components/modals/define-cardboard";
export var DefaultGroupMenu = function (props) {
    var groups = props.parameterGroups || [];
    return (_jsx(_Fragment, { children: groups.map(function (group) { return (_jsx(SidebarDrawerButton, { sidebarWidth: props.sidebarWidth ? props.sidebarWidth : 0, title: group.title, id: group.id, button: _jsx(TreeNavItem, { label: _jsx(TitleWithInfoButton, { title: group.title, description: group.helpText ? { long: group.helpText } : undefined, isVisible: props.activeMenuItem === group.id }), id: group.id }, 2), additionalRenderElement: group.id === "object_group" ? _jsx(ObjectParameterMenu, {}) : null, customRenderElement: group.specialGroupLayout === SpecialGroupLayout.CARDBOARD ? (_jsx(DefineCardboardContainer, {})) : null }, group.id)); }) }));
};
DefaultGroupMenu.displayName = "DefaultGroupMenu";

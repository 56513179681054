var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { ConvertedNumberInput } from "ui/components/elements/input/converted-number-input";
import { Dropdown } from "ui/components/elements/input/dropdown";
import { useAppSelector, useAppDispatch, selectors, actions } from "store";
import { NumberType, ChoiceType, } from "api/types";
import { CustomImageSelect } from "ui/components/elements/input/image-select";
import { imageSelectOptions } from "store/system-data/helpers";
import { ImageSelectContainer } from "ui/components/elements/input/image-select/image-select-container";
export var ParameterInput = function (_a) {
    var parameter = _a.parameter, _b = _a.hideLabel, hideLabel = _b === void 0 ? false : _b, _c = _a.autoFocus, autoFocus = _c === void 0 ? false : _c, onConfirmImageSelect = _a.onConfirmImageSelect;
    var _d = __read(useState(false), 2), hasPendingValueUpdate = _d[0], setHasPendingValueUpdate = _d[1];
    var _e = __read(useState(false), 2), pendingConfirm = _e[0], setPendingConfirm = _e[1];
    var isSystemDataLoading = useAppSelector(selectors.selectIsSystemDataLoading);
    var isOnDefineParametersStep = useAppSelector(selectors.selectIsSystemDataDefineParameterCurrentStep);
    var errors = useAppSelector(selectors.selectSystemDataErrors);
    var dispatch = useAppDispatch();
    var t = useTranslation().t;
    var parameterError = errors === null || errors === void 0 ? void 0 : errors.find(function (err) { var _a, _b; return (_b = (_a = err.extra) === null || _a === void 0 ? void 0 : _a.parameters) === null || _b === void 0 ? void 0 : _b.includes(parameter.id); });
    var errorText = parameterError
        ? t("tacton_api_errors.".concat(parameterError === null || parameterError === void 0 ? void 0 : parameterError.type), {
            parameters: [parameter.id],
            count: 1,
        })
        : undefined;
    useEffect(function () {
        if (hasPendingValueUpdate || !onConfirmImageSelect) {
            return;
        }
        if (pendingConfirm) {
            onConfirmImageSelect();
            setPendingConfirm(false);
        }
    }, [hasPendingValueUpdate, onConfirmImageSelect, pendingConfirm]);
    var onSetPendingConfirm = useCallback(function () { return setPendingConfirm(true); }, [setPendingConfirm]);
    var setValue = useCallback(function (value) { return __awaiter(void 0, void 0, void 0, function () {
        var args, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (hasPendingValueUpdate) {
                        return [2 /*return*/];
                    }
                    args = {
                        parameters: [parameter.id],
                        values: [value],
                    };
                    setHasPendingValueUpdate(true);
                    if (!isOnDefineParametersStep) return [3 /*break*/, 2];
                    return [4 /*yield*/, dispatch(actions.setParametersDefineParameters(args))];
                case 1:
                    _a = _b.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, dispatch(actions.setParametersSelectApplication(args))];
                case 3:
                    _a = _b.sent();
                    _b.label = 4;
                case 4:
                    _a;
                    setHasPendingValueUpdate(false);
                    return [2 /*return*/];
            }
        });
    }); }, [dispatch, hasPendingValueUpdate, isOnDefineParametersStep, parameter.id]);
    var clearValue = function () { return dispatch(actions.clearParameters([parameter.id])); };
    if (parameter.type === NumberType.INT ||
        parameter.type === NumberType.FLOAT) {
        var numberParameter_1 = parameter;
        return (_jsx(ConvertedNumberInput, { autoFocus: autoFocus, label: hideLabel ? undefined : numberParameter_1.label, description: numberParameter_1.description, min: numberParameter_1.min, max: numberParameter_1.max, type: numberParameter_1.type, setValue: function (value) { return setValue(value.toString()); }, value: Number(numberParameter_1.value), error: errorText, lock: {
                isLocked: numberParameter_1.isLocked,
                onLock: function () { return setValue(numberParameter_1.value); },
                onUnlock: clearValue,
                isLockDisabled: numberParameter_1.lockDisabled,
            }, unit: numberParameter_1.unit, isLoading: isSystemDataLoading }));
    }
    else if (parameter.type === ChoiceType.DROPDOWN) {
        var dropdownParameter_1 = parameter;
        var options = dropdownParameter_1.options.reduce(function (acc, option) {
            var label = option.hasConflict
                ? "".concat(option.label, " - CONFLICT")
                : option.label;
            acc.items.push(option.value);
            acc.itemLabels.push(label);
            acc.itemImages.push(option.imageSource);
            return acc;
        }, {
            items: [],
            itemLabels: [],
            itemImages: [],
        });
        return (_jsx(Dropdown, { value: dropdownParameter_1.value, label: hideLabel ? undefined : dropdownParameter_1.label, error: errorText, itemLabels: options.itemLabels, items: options.items, itemImages: options.itemImages, onValueChange: setValue, lock: {
                isLocked: dropdownParameter_1.isLocked,
                onLock: function () { return setValue(dropdownParameter_1.value); },
                onUnlock: clearValue,
                isLockDisabled: dropdownParameter_1.lockDisabled,
            }, description: dropdownParameter_1.description }));
    }
    else {
        var imageSelectParameter = parameter;
        var options = imageSelectOptions(imageSelectParameter);
        return (_jsx(ImageSelectContainer, { children: _jsx(CustomImageSelect, { onConfirmChoice: onSetPendingConfirm, value: imageSelectParameter.value, items: options.items, itemLabels: options.itemLabels, itemImages: options.itemImages, onValueChange: setValue, error: errorText, description: imageSelectParameter.description }) }));
    }
};

var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import React, { useCallback, useEffect, useState } from "react";
import { TooltipPortal } from "@animech/ui";
import { CustomUITooltip } from "ui/components/elements/custom-ui-tooltip";
import { useBlockingElementEffect } from "hooks/playcanvas/blocking-element-effect";
var BlockCanvasMouseInteraction = function () {
    useBlockingElementEffect();
    return null;
};
export var EditFieldTooltip = function (_a) {
    var children = _a.children, anchorElement = _a.anchorElement, disabled = _a.disabled;
    var _b = __read(useState(false), 2), isHovered = _b[0], setIsHovered = _b[1];
    var _c = __read(useState(false), 2), open = _c[0], setOpen = _c[1];
    useEffect(function () {
        var handleClickOutside = function () { return setOpen(false); };
        if (open && !isHovered) {
            document.addEventListener("mousedown", handleClickOutside, true);
        }
        return function () {
            document.removeEventListener("mousedown", handleClickOutside, true);
        };
    }, [isHovered, open]);
    var openTooltip = useCallback(function () {
        if (disabled)
            return;
        setOpen(true);
    }, [disabled]);
    return (_jsx(TooltipPortal, { placement: "top", anchorElement: React.cloneElement(anchorElement, {
            onClick: disabled ? undefined : openTooltip,
        }), open: open, children: _jsxs(CustomUITooltip, { open: open, children: [_jsx("div", { style: { pointerEvents: "auto" }, onMouseEnter: function () { return setIsHovered(true); }, onMouseLeave: function () { return setIsHovered(false); }, children: children }), isHovered && _jsx(BlockCanvasMouseInteraction, {})] }) }));
};

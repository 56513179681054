var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { StyledSlider } from "@animech/ui";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { graphWidth, graphMargins, graphGap, } from "ui/components/panels/graphs/constants";
export var HorizontalZoomSliderRoot = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme, color = _a.color;
    var slider = theme.components.slider;
    var sliderColor = slider.colors[color];
    var left = 110;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\t\t\tposition: relative;\n\t\t\tleft: ", "px;\n\t\t\twidth: ", "px;\n\t\t\tmargin-top: ", "px;\n\n\t\t\t", " {\n\t\t\t\t&[data-color=", "] {\n\t\t\t\t\t", " {\n\t\t\t\t\t\tbackground: ", ";\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t"], ["\n\t\t\tposition: relative;\n\t\t\tleft: ", "px;\n\t\t\twidth: ", "px;\n\t\t\tmargin-top: ", "px;\n\n\t\t\t", " {\n\t\t\t\t&[data-color=", "] {\n\t\t\t\t\t", " {\n\t\t\t\t\t\tbackground: ", ";\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t"])), left, graphWidth - left - graphMargins.right, -graphGap, StyledSlider.Root, color, StyledSlider.TrackFill, sliderColor.default.track);
});
var templateObject_1, templateObject_2;

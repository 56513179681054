import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import * as Styled from "./latest-updates.style";
import { selectors, useAppSelector } from "store";
import { ReleaseNotes } from "ui/components/elements/release-notes";
import { TabHeader } from "ui/components/panels/home-menu/tabs/tab-header";
export var LatestUpdates = function () {
    var t = useTranslation().t;
    var releaseNotes = useAppSelector(selectors.selectReleaseNotes);
    useEffect(function () {
        if (!releaseNotes) {
            return;
        }
        localStorage.setItem("lastViewedNewsflashId", releaseNotes[0].id);
    }, [releaseNotes]);
    if (!releaseNotes) {
        return null;
    }
    return (_jsxs(Styled.LatestUpdatesRoot, { children: [_jsx(TabHeader, { header: t("newsflash.title") }), _jsx(ReleaseNotes, { releaseNotes: releaseNotes })] }));
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from "@emotion/react";
import styled from "@emotion/styled";
export var Parameters = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var ParameterInputGroup = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme, separator = _a.separator;
    var gap = theme.dimensions.margin.base;
    return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\t\t\tdisplay: flex;\n\t\t\talign-items: flex-end;\n\n\t\t\t& > img {\n\t\t\t\tmargin-bottom: ", ";\n\t\t\t\tmargin-right: ", ";\n\t\t\t\twidth: 30px;\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tdisplay: flex;\n\t\t\t\tgap: ", ";\n\n\t\t\t\t& > * {\n\t\t\t\t\tposition: relative;\n\t\t\t\t}\n\n\t\t\t\t", "\n\t\t\t}\n\t\t"], ["\n\t\t\tdisplay: flex;\n\t\t\talign-items: flex-end;\n\n\t\t\t& > img {\n\t\t\t\tmargin-bottom: ", ";\n\t\t\t\tmargin-right: ", ";\n\t\t\t\twidth: 30px;\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tdisplay: flex;\n\t\t\t\tgap: ", ";\n\n\t\t\t\t& > * {\n\t\t\t\t\tposition: relative;\n\t\t\t\t}\n\n\t\t\t\t", "\n\t\t\t}\n\t\t"])), theme.dimensions.margin.xSmall, theme.dimensions.margin.small, Parameters, gap, separator === "-"
        ? css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\t\t\t\t\t\t\t& > *:not(:last-child)::before {\n\t\t\t\t\t\t\t\tcontent: \"\";\n\t\t\t\t\t\t\t\tposition: absolute;\n\t\t\t\t\t\t\t\tbottom: 26px;\n\t\t\t\t\t\t\t\tleft: 100%;\n\t\t\t\t\t\t\t\twidth: ", ";\n\t\t\t\t\t\t\t\theight: 2px;\n\t\t\t\t\t\t\t\tbackground-color: ", ";\n\t\t\t\t\t\t\t}\n\t\t\t\t\t  "], ["\n\t\t\t\t\t\t\t& > *:not(:last-child)::before {\n\t\t\t\t\t\t\t\tcontent: \"\";\n\t\t\t\t\t\t\t\tposition: absolute;\n\t\t\t\t\t\t\t\tbottom: 26px;\n\t\t\t\t\t\t\t\tleft: 100%;\n\t\t\t\t\t\t\t\twidth: ", ";\n\t\t\t\t\t\t\t\theight: 2px;\n\t\t\t\t\t\t\t\tbackground-color: ", ";\n\t\t\t\t\t\t\t}\n\t\t\t\t\t  "])), gap, theme.colors.palette.green[500]) : undefined);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;

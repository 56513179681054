var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from "@emotion/react";
import styled from "@emotion/styled";
export var ReleaseNote = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var Date = styled.p(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
export var ReleaseNotesContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme;
    return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\t\t\toverflow: auto;\n\t\t\theight: 80%;\n\n\t\t\t", " {\n\t\t\t\tmargin: ", " 0;\n\t\t\t\tpadding: 14px;\n\t\t\t\tborder-radius: 6px;\n\n\t\t\t\t", " {\n\t\t\t\t\tcolor: ", ";\n\t\t\t\t}\n\n\t\t\t\tp {\n\t\t\t\t\tfont-size: ", ";\n\t\t\t\t}\n\t\t\t}\n\t\t"], ["\n\t\t\toverflow: auto;\n\t\t\theight: 80%;\n\n\t\t\t", " {\n\t\t\t\tmargin: ", " 0;\n\t\t\t\tpadding: 14px;\n\t\t\t\tborder-radius: 6px;\n\n\t\t\t\t", " {\n\t\t\t\t\tcolor: ", ";\n\t\t\t\t}\n\n\t\t\t\tp {\n\t\t\t\t\tfont-size: ", ";\n\t\t\t\t}\n\t\t\t}\n\t\t"])), ReleaseNote, theme.dimensions.margin.large, Date, theme.colors.palette.green[500], theme.typography.fontSize.small);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;

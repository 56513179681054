var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createElement as _createElement } from "@emotion/react";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { ReferenceLine } from "recharts";
import { selectors, useAppSelector } from "store";
import { vacuumPumpIdPrefix } from "ui/components/panels/graphs/constants";
import { useNumberConversion } from "hooks/number-conversion";
import { Unit, getAprxPixelWidthOfNumber } from "utility/number";
import { TextBox, TextBoxWithEditableTooltip, TextBoxWithTooltip, } from "ui/components/elements/chart/text-box";
import { useGraphDataContext } from "providers/graph-panel";
import { atmColor, energySavingColor, partSecuredColor } from "store/types";
import { systemDataConstants } from "api/system-data";
var segmentDash = "7 4";
var segmentWidth = 2;
var segmentColor = "#ccc";
var SVGTextBox = function (props) {
    var xRect = props.x, y = props.y, width = props.width, height = props.height, text = props.text, color = props.color, placement = props.placement, tooltipText = props.tooltipText, description = props.description, parameter = props.parameter;
    var yRect = placement === "top"
        ? y - height
        : placement === "bottom"
            ? y
            : y - height / 2;
    if (parameter) {
        return (_jsx(TextBoxWithEditableTooltip, { parameter: parameter, height: height, width: width, text: text !== null && text !== void 0 ? text : "", x: xRect, y: yRect, color: color }));
    }
    if (tooltipText && description) {
        return (_jsx(TextBoxWithTooltip, { tooltip: tooltipText !== null && tooltipText !== void 0 ? tooltipText : "", description: description !== null && description !== void 0 ? description : undefined, height: height, width: width, text: text !== null && text !== void 0 ? text : "", x: xRect, y: yRect, color: color }));
    }
    return (_jsx(TextBox, { height: height, width: width, text: text !== null && text !== void 0 ? text : "", x: xRect, y: yRect, color: color }));
};
var CustomizedSegmentLabel = function (props) {
    var viewBox = props.viewBox, value = props.value, color = props.color, placement = props.placement, isATM = props.isATM, tooltipText = props.tooltipText, description = props.description, parameter = props.parameter;
    var cartesianViewbox = viewBox;
    var x = cartesianViewbox.x, y = cartesianViewbox.y;
    var calculatedWidthInPixels = getAprxPixelWidthOfNumber(Number(value));
    var width = isATM ? 32 : calculatedWidthInPixels;
    var height = 18;
    var textBoxX = 20;
    var x1 = textBoxX + width + 2;
    var x2 = isATM ? x1 + 20 : x;
    return (_jsxs(_Fragment, { children: [_jsx(SVGTextBox, { x: textBoxX, y: y !== null && y !== void 0 ? y : 0, width: width, height: height, text: value, color: color !== null && color !== void 0 ? color : "white", placement: placement, tooltipText: tooltipText !== null && tooltipText !== void 0 ? tooltipText : "", description: description, parameter: parameter }), _jsx("line", { stroke: segmentColor, strokeDasharray: segmentDash, strokeWidth: segmentWidth, 
                // The +5 is necsarry for the dashed line to have a gap right before it connects to the referenceLine dashed line.
                x1: x1, x2: x2, y1: y, y2: y })] }));
};
export var SegmentLines = function (props) {
    var _a, _b;
    var t = useTranslation().t;
    var graphData = useAppSelector(selectors.selectGraphData);
    var parsedGraphData = useAppSelector(selectors.selectParsedGraphData);
    var convertNumber = useNumberConversion();
    var zoomedTimeDomain = useGraphDataContext().zoomedTimeDomain;
    if (!parsedGraphData)
        return null;
    var vacuumPumpData = parsedGraphData.nodeData;
    var systemProperty = graphData.systemProperty, governingPump = graphData.governingPump;
    var timeData = systemProperty.timeData, psIndex = systemProperty.psIndex, esIndex = systemProperty.esIndex, showEs = systemProperty.showEs;
    var segmentXInitialPoint = (_a = zoomedTimeDomain === null || zoomedTimeDomain === void 0 ? void 0 : zoomedTimeDomain[0]) !== null && _a !== void 0 ? _a : timeData[0];
    var xUpperBound = (_b = zoomedTimeDomain === null || zoomedTimeDomain === void 0 ? void 0 : zoomedTimeDomain[1]) !== null && _b !== void 0 ? _b : timeData[timeData.length - 1];
    var segmentLines = [
        {
            index: 0,
            translationKey: "graph_panel.pump_x_label_ATM",
            color: atmColor,
            placement: "center",
            invertedValue: true,
            value: 0,
            tooltipText: "dashboard.atm",
            descriptionKey: "descriptions.dashboard.release_time_to_place_part",
        },
        {
            index: psIndex,
            color: partSecuredColor,
            placement: "top",
            invertedValue: true,
            parameter: systemDataConstants.parameterNames.partSecuredLevel,
        },
        {
            index: esIndex,
            placement: "bottom",
            color: energySavingColor,
            disabled: !showEs,
            invertedValue: true,
            parameter: systemDataConstants.parameterNames.energySavingLevel,
        },
    ];
    return (_jsx(_Fragment, { children: segmentLines.map(function (segmentLine) {
            var _a;
            if (segmentLine.disabled)
                return null;
            var isATM = segmentLine.value !== undefined;
            var value = (_a = segmentLine.value) !== null && _a !== void 0 ? _a : vacuumPumpData[segmentLine.index][vacuumPumpIdPrefix + governingPump.id];
            var label = segmentLine.translationKey
                ? t(segmentLine.translationKey)
                : convertNumber(segmentLine.invertedValue ? value * -1 : value, Unit.KPA).value.toString();
            var tooltipText = segmentLine.tooltipText
                ? t(segmentLine.tooltipText)
                : "";
            var description = segmentLine.descriptionKey
                ? { long: t(segmentLine.descriptionKey) }
                : undefined;
            var intersectionVisible = timeData[segmentLine.index] > segmentXInitialPoint &&
                timeData[segmentLine.index] < xUpperBound;
            return (_createElement(ReferenceLine, __assign({}, props, { key: label, segment: [
                    { x: segmentXInitialPoint, y: value },
                    {
                        x: intersectionVisible
                            ? timeData[segmentLine.index]
                            : segmentXInitialPoint,
                        y: value,
                    },
                ], stroke: segmentColor, strokeDasharray: segmentDash, strokeWidth: segmentWidth, label: _jsx(CustomizedSegmentLabel, { value: label, color: segmentLine.color, placement: segmentLine.placement, isATM: isATM, tooltipText: tooltipText, description: description, parameter: segmentLine.parameter }) })));
        }) }));
};
SegmentLines.displayName = ReferenceLine.displayName;
SegmentLines.defaultProps = ReferenceLine.defaultProps;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { InputContainer } from "ui/components/elements/input/input-container/input-container.style";
import { tabMaxWidth } from "ui/components/panels/home-menu/home-menu.style";
export var SystemList = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var ConfirmChoice = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
export var EmptyText = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
export var PredefinedSystemsTab = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme;
    return css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\t\t\tdisplay: grid;\n\t\t\tgrid-template-columns: 1fr minmax(0, ", ") 1fr;\n\t\t\theight: 100%;\n\t\t\twidth: 100%;\n\n\t\t\t", " {\n\t\t\t\tgrid-column: 2;\n\t\t\t}\n\t\t\t", " {\n\t\t\t\tgrid-column: 3;\n\t\t\t\tdisplay: flex;\n\t\t\t\tjustify-content: flex-end;\n\t\t\t\talign-items: flex-start;\n\t\t\t\tmargin: ", ";\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tfont-size: ", ";\n\t\t\t\ttext-align: center;\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tmax-width: 135px;\n\t\t\t\ttext-align: center;\n\t\t\t}\n\t\t"], ["\n\t\t\tdisplay: grid;\n\t\t\tgrid-template-columns: 1fr minmax(0, ", ") 1fr;\n\t\t\theight: 100%;\n\t\t\twidth: 100%;\n\n\t\t\t", " {\n\t\t\t\tgrid-column: 2;\n\t\t\t}\n\t\t\t", " {\n\t\t\t\tgrid-column: 3;\n\t\t\t\tdisplay: flex;\n\t\t\t\tjustify-content: flex-end;\n\t\t\t\talign-items: flex-start;\n\t\t\t\tmargin: ", ";\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tfont-size: ", ";\n\t\t\t\ttext-align: center;\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tmax-width: 135px;\n\t\t\t\ttext-align: center;\n\t\t\t}\n\t\t"])), tabMaxWidth, SystemList, ConfirmChoice, theme.dimensions.margin.base, EmptyText, theme.typography.fontSize.small, InputContainer);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;

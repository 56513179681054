var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { StyledBaseModal, StyledButton } from "@animech/ui";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ReleaseNote } from "ui/components/elements/release-notes/release-notes.style";
import { CustomBaseModal } from "ui/components/modals/custom-base-modal/custom-base-modal";
export var Footer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var NewsflashRoot = styled(CustomBaseModal)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme;
    var padding = theme.dimensions.margin.xxLarge;
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\t\t\t", " {\n\t\t\t\twidth: clamp(560px, 55vw, 850px);\n\t\t\t\taspect-ratio: 1/1;\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tpadding: ", ";\n\t\t\t\tpadding-bottom: 0;\n\t\t\t\tfont-size: ", ";\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tpadding: ", ";\n\t\t\t\tdisplay: flex;\n\t\t\t\tflex-direction: column;\n\t\t\t\tjustify-content: space-between;\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tdisplay: flex;\n\t\t\t\tjustify-content: center;\n\n\t\t\t\t", " {\n\t\t\t\t\tfont-size: ", ";\n\t\t\t\t\tmargin: ", " 0;\n\t\t\t\t}\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tbackground: #ffffff0a;\n\t\t\t}\n\t\t"], ["\n\t\t\t", " {\n\t\t\t\twidth: clamp(560px, 55vw, 850px);\n\t\t\t\taspect-ratio: 1/1;\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tpadding: ", ";\n\t\t\t\tpadding-bottom: 0;\n\t\t\t\tfont-size: ", ";\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tpadding: ", ";\n\t\t\t\tdisplay: flex;\n\t\t\t\tflex-direction: column;\n\t\t\t\tjustify-content: space-between;\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tdisplay: flex;\n\t\t\t\tjustify-content: center;\n\n\t\t\t\t", " {\n\t\t\t\t\tfont-size: ", ";\n\t\t\t\t\tmargin: ", " 0;\n\t\t\t\t}\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tbackground: #ffffff0a;\n\t\t\t}\n\t\t"])), StyledBaseModal.Content, StyledBaseModal.Header, padding, theme.typography.fontSize.xLarge, StyledBaseModal.Body, padding, Footer, StyledButton.Root, theme.typography.fontSize.base, theme.dimensions.margin.large, ReleaseNote);
});
var templateObject_1, templateObject_2, templateObject_3;

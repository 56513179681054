var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { systemDataConstants } from "api/system-data";
export var getNextAvailableSelectionId = function (componentSelections, assignedSelections) {
    var e_1, _a;
    try {
        for (var _b = __values(Object.keys(componentSelections)), _c = _b.next(); !_c.done; _c = _b.next()) {
            var selectionId = _c.value;
            if (!componentSelections[selectionId] &&
                !Object.values(assignedSelections).includes(selectionId)) {
                return selectionId;
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return null;
};
export var getAllAssignedSelectionFields = function (state) {
    return __spreadArray(__spreadArray(__spreadArray(__spreadArray([], __read(Object.values(state.cupSelectionMap)), false), __read(Object.values(state.pumpSelectionCentralMap)), false), __read(Object.values(state.pumpSelectionDecentralMap)), false), __read(Object.values(state.cupMountingSelectionMap)), false);
};
export var getAllSelections = function (systemData) {
    return Object.assign({}, systemData.suctionCupSelections, systemData.vacuumPumpSelectionsCentralized, systemData.vacuumPumpSelectionsDecentralized, systemData.cupMountingSelections);
};
export var getFailedParameters = function (parameterNames, parameterValues, systemData) {
    try {
        var failedParameters = parameterNames.map(function (name, ix) {
            if (systemData.parameters[name].value !== parameterValues[ix]) {
                return name;
            }
            else {
                return null;
            }
        });
        return failedParameters.filter(Boolean);
    }
    catch (_a) {
        return undefined;
    }
};
export var getFailedComponents = function (parameterNames, parameterValues, systemData) {
    try {
        var selections_1 = getAllSelections(systemData);
        var failedParameters = parameterNames.map(function (name, ix) {
            if (selections_1[name] !== parameterValues[ix]) {
                return parameterValues[ix];
            }
            else {
                return null;
            }
        });
        return failedParameters.filter(Boolean);
    }
    catch (_a) {
        return undefined;
    }
};
export var isCentralizedSystem = function (systemData) {
    var vacuumConfigType = systemData === null || systemData === void 0 ? void 0 : systemData.parameters[systemDataConstants.parameterNames.vacuumSystemType];
    if (!vacuumConfigType) {
        return;
    }
    return (vacuumConfigType.value ===
        systemDataConstants.parameterValues.centralizedSystem);
};
export var getVacuumPumpComponent = function (nodeId, pumpSelectionCentralMap, pumpSelectionDecentralMap, systemData) {
    if (!systemData)
        return;
    var vacuumPumpSelectionsCentralized = systemData.vacuumPumpSelectionsCentralized, vacuumPumpSelectionsDecentralized = systemData.vacuumPumpSelectionsDecentralized;
    var isSystemCentralized = isCentralizedSystem(systemData);
    var pumpSelectionMap = isSystemCentralized
        ? pumpSelectionCentralMap
        : pumpSelectionDecentralMap;
    var selections = isSystemCentralized
        ? vacuumPumpSelectionsCentralized
        : vacuumPumpSelectionsDecentralized;
    var selectionId = pumpSelectionMap[nodeId];
    if (!selectionId)
        return;
    var value = selections[selectionId];
    if (!value)
        return;
    return systemData.vacuumPumpComponents[value] || undefined;
};
export var getCupComponent = function (nodeId, cupSelectionMap, systemData) {
    if (!systemData)
        return;
    var suctionCupSelections = systemData.suctionCupSelections;
    var selectionId = cupSelectionMap[nodeId];
    if (!selectionId)
        return;
    var value = suctionCupSelections[selectionId];
    if (!value)
        return;
    return systemData.suctionCupComponents[value] || undefined;
};
export var imageSelectOptions = function (parameter) {
    return parameter.options.reduce(function (acc, option) {
        var _a;
        acc.items.push(option.value);
        acc.itemLabels.push(option.label);
        acc.itemImages.push((_a = option.imageSource) !== null && _a !== void 0 ? _a : "img/app/intro-background.jpg");
        return acc;
    }, {
        items: [],
        itemLabels: [],
        itemImages: [],
    });
};

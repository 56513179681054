import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import React, { useMemo } from "react";
import { ParameterInput } from "ui/components/elements/parameter-input";
import { selectors, useAppSelector } from "store";
import { EditFieldTooltip } from "ui/components/panels/canvas/overlay-elements/tooltip-components/edit-field-tooltip";
export var EditParameterTooltip = function (_a) {
    var anchorElement = _a.anchorElement, parameterNames = _a.parameterNames, disabled = _a.disabled;
    var systemDataDefineParameters = useAppSelector(selectors.selectSystemDataDefineParameters);
    var parameters = useMemo(function () {
        if (!systemDataDefineParameters) {
            return [];
        }
        return parameterNames
            .filter(function (name) { return systemDataDefineParameters.parameters[name] !== undefined; })
            .map(function (name) { return systemDataDefineParameters.parameters[name]; });
    }, [systemDataDefineParameters, parameterNames]);
    return (_jsx(EditFieldTooltip, { anchorElement: anchorElement, disabled: disabled, children: parameters.map(function (parameter, i) { return (_jsx(ParameterInput, { parameter: parameter, autoFocus: i === 0 }, parameter.id)); }) }));
};

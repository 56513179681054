var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from "@emotion/react";
import styled from "@emotion/styled";
export var TabHeader = styled.h2(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\t\t\tborder-bottom: 1px solid #ffffff59;\n\t\t\tfont-size: ", ";\n\t\t\tmargin-bottom: ", ";\n\t\t\tpadding: ", " 0;\n\t\t\twidth: 100%;\n\t\t"], ["\n\t\t\tborder-bottom: 1px solid #ffffff59;\n\t\t\tfont-size: ", ";\n\t\t\tmargin-bottom: ", ";\n\t\t\tpadding: ", " 0;\n\t\t\twidth: 100%;\n\t\t"])), theme.typography.fontSize.large, theme.dimensions.margin.large, theme.dimensions.margin.large);
});
export var TabSubHeader = styled.h3(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme;
    return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\t\t\tfont-size: ", ";\n\t\t\tmargin: 0 0 ", " 0;\n\t\t"], ["\n\t\t\tfont-size: ", ";\n\t\t\tmargin: 0 0 ", " 0;\n\t\t"])), theme.typography.fontSize.base, theme.dimensions.margin.large);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;

var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { persistConfigs } from "store/persist";
var persistIfExists = function (key, map) {
    var value = map.get(key);
    if (value) {
        window.sessionStorage.setItem("persist:".concat(key), value);
    }
};
export var persistTemplate = function (template) {
    var e_1, _a;
    var parts = template.split("&").reduce(function (acc, param) {
        var _a = __read(param.split("="), 2), key = _a[0], value = _a[1];
        acc.set(key, decodeURIComponent(value));
        return acc;
    }, new Map());
    try {
        for (var persistConfigs_1 = __values(persistConfigs), persistConfigs_1_1 = persistConfigs_1.next(); !persistConfigs_1_1.done; persistConfigs_1_1 = persistConfigs_1.next()) {
            var config = persistConfigs_1_1.value;
            persistIfExists(config.key, parts);
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (persistConfigs_1_1 && !persistConfigs_1_1.done && (_a = persistConfigs_1.return)) _a.call(persistConfigs_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
};

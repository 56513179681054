import { useEffect } from "react";
import { actions, selectors, useAppDispatch, useAppSelector } from "store";
export var useLoadReleaseNotes = function () {
    var dispatch = useAppDispatch();
    var releaseNotes = useAppSelector(selectors.selectReleaseNotes);
    var isLoading = useAppSelector(selectors.selectIsSystemDataLoading);
    var isOnSelectApplicationStep = useAppSelector(selectors.selectIsSystemDataSelectApplicationCurrentStep);
    var loadReleaseNotes = function () {
        if (isLoading || releaseNotes || isOnSelectApplicationStep) {
            return;
        }
        dispatch(actions.getSystemDataSelectApplication());
    };
    useEffect(loadReleaseNotes, [
        releaseNotes,
        isLoading,
        dispatch,
        isOnSelectApplicationStep,
    ]);
};

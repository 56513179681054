var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { useEffect } from "react";
import { usePrevious } from "@animech/ui";
import { useLocation } from "react-router-dom";
import { ActionCreators as UndoActionCreators } from "redux-undo";
import { useAppDispatch, useAppSelector } from "./store";
import { actions } from "./actions";
import { selectors } from "./selectors";
import { TubeConnectionId, tubeDiameterOptions, } from "./types";
import { useCalculationObservers } from "./calculation-observers";
import { PageRoute } from "utility/url";
export var Observers = function () {
    var dispatch = useAppDispatch();
    var systemDataDefineParameters = useAppSelector(selectors.selectSystemDataDefineParameters);
    var systemDataSelectApplication = useAppSelector(selectors.selectSystemDataSelectApplication);
    var isCentralizedSystem = useAppSelector(selectors.selectIsCentralizedSystem);
    var objectShapes = useAppSelector(selectors.selectObjectShapes);
    var currentObjectShape = useAppSelector(selectors.selectObjectShape);
    var currentRoute = useLocation().pathname;
    useEffect(function () {
        if (currentRoute !== PageRoute.SYSTEM_DESIGN)
            return;
        if (systemDataDefineParameters)
            return;
        if (!systemDataSelectApplication)
            return;
        dispatch(actions.getSystemDataDefineParameters());
    }, [
        dispatch,
        systemDataSelectApplication,
        currentRoute,
        systemDataDefineParameters,
    ]);
    useEffect(function () {
        if (currentRoute !== PageRoute.SYSTEM_DESIGN)
            return;
        if (systemDataSelectApplication)
            return;
        dispatch(actions.getSystemDataSelectApplication());
    }, [dispatch, systemDataSelectApplication, currentRoute]);
    // objectShapes is no longer a static list with only metal objects. It will change depending on selected application.
    // therefore we need to reset current selected object to the first available object from the list whenever it updates.
    useEffect(function () {
        if (!objectShapes)
            return;
        var currentObjectExistsInObjectShapes = Object.keys(objectShapes).find(function (objectShape) { return objectShape === currentObjectShape; });
        // If the current object exists in the list (user probably just refreshed the page), then we don't need to reset the selection.
        if (currentObjectExistsInObjectShapes)
            return;
        dispatch(actions.setObjectShape(Object.keys(objectShapes)[0]));
    }, [currentObjectShape, dispatch, objectShapes]);
    var previousIsCentralizedSystem = usePrevious(isCentralizedSystem);
    useEffect(function () {
        if (previousIsCentralizedSystem === undefined ||
            previousIsCentralizedSystem === isCentralizedSystem)
            return;
        dispatch(actions.deleteAllEdges());
        dispatch(actions.clearPerformanceData());
        dispatch(actions.setOverlayData(undefined));
        /* We need to reset the undo-history everytime the user changes between centralized/decentralized.
         * To avoid being able to revert scene nodes that are incompatible with the tacton state. */
        dispatch(UndoActionCreators.clearHistory());
    }, [isCentralizedSystem, previousIsCentralizedSystem, dispatch]);
    useUpdateDiameterOfTubeConnectionsObserver();
    useAddLatestSelectedCupObserver();
    useCalculationObservers();
    return null;
};
/**
 * TODO: [PERFORMANCE] This observer is a source of slowdown when the user has a complex configuration.
 * A way to boost performance would be to not run this logic inside a useEffect, but instead run it
 * only at times when we know it is needed. That could be a performance boost at the cost of some
 * increased application complexity.
 */
function useUpdateDiameterOfTubeConnectionsObserver() {
    var dispatch = useAppDispatch();
    var tubeConnections = useAppSelector(selectors.selectTubeConnections);
    var attachments = useAppSelector(selectors.selectAttachments);
    var edges = useAppSelector(selectors.selectEdges);
    var unitSystem = useAppSelector(selectors.selectUnitSystem);
    useEffect(function () {
        var e_1, _a;
        var shouldNotUpdateAutomatically = function (tubeConnection) {
            return (tubeConnection.component.type ===
                TubeConnectionId.TOOL_CHANGER);
        };
        var getConnectedEdges = function (tubeConnection) {
            return tubeConnection.attachmentIds
                .map(function (attachmentId) { var _a; return (_a = attachments[attachmentId]) === null || _a === void 0 ? void 0 : _a.edgeId; })
                .filter(function (edgeId) { return edgeId !== undefined; })
                .map(function (edgeId) { return edges[edgeId]; });
        };
        var hasNoConnectedEdges = function (edges) { return edges.length === 0; };
        var getEdgeDiameters = function (connectedEdges) {
            var fromEdgeToOuterDiameter = function (edge) {
                return tubeDiameterOptions[unitSystem][edge.defaultDiameterIndex].outerValue;
            };
            return connectedEdges.map(fromEdgeToOuterDiameter);
        };
        var updateTubeConnectionDiameters = function (tubeConnection) {
            if (shouldNotUpdateAutomatically(tubeConnection))
                return;
            var connectedEdges = getConnectedEdges(tubeConnection);
            if (hasNoConnectedEdges(connectedEdges))
                return;
            var edgeDiameters = getEdgeDiameters(connectedEdges);
            var noDiameterHasChanged = !edgeDiameters.some(function (diameter, i) {
                return diameter !==
                    tubeConnection.component.diameters[i];
            });
            if (noDiameterHasChanged) {
                return;
            }
            dispatch(actions.setTubeConnectionDiameters({
                nodeIds: [tubeConnection.id],
                diameters: edgeDiameters,
            }));
        };
        try {
            for (var tubeConnections_1 = __values(tubeConnections), tubeConnections_1_1 = tubeConnections_1.next(); !tubeConnections_1_1.done; tubeConnections_1_1 = tubeConnections_1.next()) {
                var tubeConnection = tubeConnections_1_1.value;
                updateTubeConnectionDiameters(tubeConnection);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (tubeConnections_1_1 && !tubeConnections_1_1.done && (_a = tubeConnections_1.return)) _a.call(tubeConnections_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    }, [edges, attachments, tubeConnections, dispatch, unitSystem]);
}
/**
 * Check whether there is a latest selected cup and latest added grip
 * position and add that cup to the latest added grip position.
 */
function useAddLatestSelectedCupObserver() {
    var dispatch = useAppDispatch();
    var systemDataDefineParameters = useAppSelector(selectors.selectSystemDataDefineParameters);
    var cupSelectionMap = useAppSelector(selectors.selectCupSelectionMap);
    var latestSelectedCup = useAppSelector(selectors.selectLatestSelectedCup);
    var latestAddedGripPosition = useAppSelector(selectors.selectLatestAddedGripPosition);
    var field = latestAddedGripPosition && cupSelectionMap[latestAddedGripPosition];
    var valueAlreadySet = field &&
        (systemDataDefineParameters === null || systemDataDefineParameters === void 0 ? void 0 : systemDataDefineParameters.suctionCupSelections[field]) ===
            latestSelectedCup;
    useEffect(function () {
        if (latestSelectedCup === undefined ||
            latestAddedGripPosition === undefined ||
            valueAlreadySet)
            return;
        dispatch(actions.selectCup({
            nodeIds: [latestAddedGripPosition],
            value: latestSelectedCup,
        }));
    }, [dispatch, latestAddedGripPosition, latestSelectedCup, valueAlreadySet]);
}

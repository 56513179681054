var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
export var getURLParameter = function (param) {
    var _a;
    var params = new URLSearchParams(window.location.hash.split("?")[1]);
    return (_a = params.get(param)) !== null && _a !== void 0 ? _a : undefined;
};
export var clearURLParameter = function (param) {
    var _a = __read(window.location.hash.split("?"), 2), path = _a[0], search = _a[1];
    var pathName = window.location.pathname;
    var params = new URLSearchParams(search);
    params.delete(param);
    window.history.replaceState([], document.title, window.location.origin + pathName + path + "?" + params.toString());
};
export var PageRoute;
(function (PageRoute) {
    PageRoute["WELCOME_SCREEN"] = "/";
    PageRoute["LATEST_UPDATES"] = "/news";
    PageRoute["SELECT_APPLICATION"] = "/select-application";
    PageRoute["DEFINE_PARAMETERS"] = "/define-parameters";
    PageRoute["SYSTEM_DESIGN"] = "/system-design";
    PageRoute["BLANK_SYSTEM"] = "/blank_system";
    PageRoute["APPLICATION"] = "/application";
    PageRoute["PREDEFINED_SYSTEMS"] = "/predefined_systems";
})(PageRoute || (PageRoute = {}));

import { useEffect } from "react";
import { actions, selectors, useAppDispatch, useAppSelector } from "store";
export var useGetDataForWizardStep = function (isSelectApplicationStep) {
    var dispatch = useAppDispatch();
    var systemDataSelectApplication = useAppSelector(selectors.selectSystemDataSelectApplication);
    useEffect(function () {
        if (!isSelectApplicationStep)
            return;
        dispatch(actions.getSystemDataSelectApplication());
    }, [dispatch, isSelectApplicationStep]);
    useEffect(function () {
        if (isSelectApplicationStep)
            return;
        if (!systemDataSelectApplication) {
            dispatch(actions.getSystemDataSelectApplication());
            return;
        }
        dispatch(actions.getSystemDataDefineParameters());
    }, [dispatch, isSelectApplicationStep, systemDataSelectApplication]);
};

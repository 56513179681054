var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "api/system-data";
import { ApiErrorType } from "api/errors";
import { TactonStepName } from "api/tacton/types";
import { getFailedComponents, getFailedParameters, } from "store/system-data/helpers";
export var initialState = {
    dataState: { isLoading: false },
};
var setSystemDataSelectApplicationCurrentStep = function (state, action) {
    if (state.dataState.data) {
        state.dataState.data.currentStep = action.payload;
    }
};
var getData = createAsyncThunk("select-application-data/getData", function (_, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    var data, response, err_1, error, errors, setPending, pendingParameters, parameterNames_1, parameterValues_1, response;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 2, , 3]);
                return [4 /*yield*/, api.getData(thunkApi.extra.configId, TactonStepName.SELECT_APPLICATION, thunkApi.extra.ticket)];
            case 1:
                response = _b.sent();
                data = response;
                return [3 /*break*/, 3];
            case 2:
                err_1 = _b.sent();
                error = err_1;
                return [2 /*return*/, thunkApi.rejectWithValue({
                        errors: [
                            { message: error.message, type: error.type, extra: error.extra },
                        ],
                    })];
            case 3:
                errors = [];
                setPending = function (parameterNames, parameterValues, onError) { return __awaiter(void 0, void 0, void 0, function () {
                    var response, err_2, error;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (parameterNames.length === 0)
                                    return [2 /*return*/];
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, api.submitParameters(thunkApi.extra.configId, parameterNames, parameterValues, thunkApi.extra.ticket)];
                            case 2:
                                response = _a.sent();
                                return [3 /*break*/, 4];
                            case 3:
                                err_2 = _a.sent();
                                error = err_2;
                                onError(error);
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/, response];
                        }
                    });
                }); };
                pendingParameters = (_a = thunkApi.getState().systemDataSelectApplication.dataState.pending) === null || _a === void 0 ? void 0 : _a.parameters;
                if (!pendingParameters) return [3 /*break*/, 5];
                parameterNames_1 = Object.keys(pendingParameters);
                parameterValues_1 = parameterNames_1.map(function (p) { return pendingParameters[p]; });
                return [4 /*yield*/, setPending(parameterNames_1, parameterValues_1, function (err) {
                        var errorData = err.systemData;
                        var failedParameters = errorData
                            ? getFailedParameters(parameterNames_1, parameterValues_1, errorData)
                            : undefined;
                        errors.push({
                            message: err.message,
                            type: ApiErrorType.RESTORE_SAVED_ERROR,
                            extra: {
                                parameters: failedParameters,
                            },
                        });
                        if (errorData) {
                            data = errorData;
                        }
                    })];
            case 4:
                response = _b.sent();
                if (response) {
                    data = response;
                }
                _b.label = 5;
            case 5:
                if (errors.length > 0) {
                    return [2 /*return*/, thunkApi.rejectWithValue({ errors: errors, data: data })];
                }
                return [2 /*return*/, data];
        }
    });
}); }, {
    condition: function (_, thunkApi) {
        var systemDataSelectApplication = thunkApi.getState().systemDataSelectApplication;
        return !systemDataSelectApplication.dataState.isLoading;
    },
});
var setParameters = createAsyncThunk("select-application-data/setParameters", function (payload, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_3, error, errorData, failedParameters, failedComponents;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, api.submitParameters(thunkApi.extra.configId, payload.parameters, payload.values, thunkApi.extra.ticket)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
            case 2:
                err_3 = _a.sent();
                error = err_3;
                errorData = error.systemData;
                failedParameters = errorData
                    ? getFailedParameters(payload.parameters, payload.values, errorData)
                    : undefined;
                failedComponents = errorData
                    ? getFailedComponents(payload.parameters, payload.values, errorData)
                    : undefined;
                return [2 /*return*/, thunkApi.rejectWithValue({
                        error: {
                            message: error.message,
                            type: error.type,
                            extra: {
                                parameters: failedParameters,
                                components: failedComponents,
                            },
                        },
                        data: errorData,
                    })];
            case 3: return [2 /*return*/];
        }
    });
}); }, {
    condition: function (_, thunkApi) {
        var systemDataSelectApplication = thunkApi.getState().systemDataSelectApplication;
        return !systemDataSelectApplication.dataState.isLoading;
    },
});
var selectAppDataSlice = createSlice({
    name: "select-application-data",
    initialState: initialState,
    reducers: { setSystemDataSelectApplicationCurrentStep: setSystemDataSelectApplicationCurrentStep },
    extraReducers: function (builder) {
        builder.addCase(getData.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.dataState = { data: payload, isLoading: false };
        });
        builder.addCase(getData.rejected, function (state, _a) {
            var payload = _a.payload;
            state.dataState.errors = payload === null || payload === void 0 ? void 0 : payload.errors;
            state.dataState.isLoading = false;
            if (payload === null || payload === void 0 ? void 0 : payload.data) {
                state.dataState.data = payload.data;
            }
        });
        builder.addCase(getData.pending, function (state) {
            state.dataState.isLoading = true;
        });
        builder.addCase(setParameters.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.dataState = { data: payload, isLoading: false };
        });
        builder.addCase(setParameters.rejected, function (state, _a) {
            var payload = _a.payload;
            state.dataState.errors = (payload === null || payload === void 0 ? void 0 : payload.error) ? [payload.error] : undefined;
            state.dataState.isLoading = false;
            if (payload === null || payload === void 0 ? void 0 : payload.data) {
                state.dataState.data = payload.data;
            }
        });
        builder.addCase(setParameters.pending, function (state) {
            state.dataState.isLoading = true;
        });
    },
});
var reducer = selectAppDataSlice.reducer;
export var callableActions = {
    setSystemDataSelectApplicationCurrentStep: selectAppDataSlice.actions.setSystemDataSelectApplicationCurrentStep,
    getSystemDataSelectApplication: getData,
    setParametersSelectApplication: setParameters,
};
export default reducer;

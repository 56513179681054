import { persistConfig as persistConfigDefineParameters } from "./system-data/define-parameters/persist-config";
import { persistConfig as persistConfigSelectApplication } from "./system-data/select-application/persist-config";
import { persistConfig as scenePersistConfig } from "store/scene/persist-config";
import { persistConfig as uiPersistConfig } from "store/ui/persist-config";
export var persistConfigs = [
    persistConfigDefineParameters,
    persistConfigSelectApplication,
    scenePersistConfig,
    uiPersistConfig,
];

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { StyledDropDown } from "@animech/ui";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
export var SystemTypeSwitch = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\t\t\t", " {\n\t\t\t\tdisplay: inline-block;\n\t\t\t\tmargin: 0;\n\t\t\t\tpadding-right: ", ";\n\t\t\t\tpadding-left: ", ";\n\t\t\t\tbox-shadow: none !important;\n\t\t\t\tbackground: none !important;\n\t\t\t\t", " {\n\t\t\t\t\tpadding: 0;\n\t\t\t\t}\n\t\t\t}\n\t\t"], ["\n\t\t\t", " {\n\t\t\t\tdisplay: inline-block;\n\t\t\t\tmargin: 0;\n\t\t\t\tpadding-right: ", ";\n\t\t\t\tpadding-left: ", ";\n\t\t\t\tbox-shadow: none !important;\n\t\t\t\tbackground: none !important;\n\t\t\t\t", " {\n\t\t\t\t\tpadding: 0;\n\t\t\t\t}\n\t\t\t}\n\t\t"])), StyledDropDown.Root, theme.dimensions.margin.base, theme.dimensions.margin.base, StyledDropDown.Container);
});
var templateObject_1, templateObject_2;

var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
//eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useDispatch, useSelector } from "react-redux";
import { configureStore, combineReducers, } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import { persistConfig as persistConfigDefineParameters } from "./system-data/define-parameters/persist-config";
import { persistConfig as persistConfigSelectApplication } from "./system-data/select-application/persist-config";
import { listenerMiddleware } from "./listener-middleware/listener-middleware";
import systemDataReducerDefineParameters from "store/system-data/define-parameters/reducer";
import { getURLParameter, clearURLParameter } from "utility/url";
import sceneReducer from "store/scene/reducer";
import systemDataReducerSelectApplication from "store/system-data/select-application/reducer";
import systemPerformanceReducer from "store/system-performance/reducer";
import { persistConfig as scenePersistConfig } from "store/scene/persist-config";
import { persistConfig as uiPersistConfig } from "store/ui/persist-config";
import uiReducer from "store/ui/reducer";
import { persistConfigs } from "store/persist";
export var rootReducer = combineReducers({
    scene: sceneReducer,
    systemDataDefineParameters: systemDataReducerDefineParameters,
    systemDataSelectApplication: systemDataReducerSelectApplication,
    ui: uiReducer,
    systemPerformance: systemPerformanceReducer,
});
export var persistedRootReducer = combineReducers({
    scene: persistReducer(scenePersistConfig, sceneReducer),
    systemDataDefineParameters: persistReducer(persistConfigDefineParameters, systemDataReducerDefineParameters),
    systemDataSelectApplication: persistReducer(persistConfigSelectApplication, systemDataReducerSelectApplication),
    ui: persistReducer(uiPersistConfig, uiReducer),
    systemPerformance: systemPerformanceReducer,
});
var storeURLParameter = function (persistKey) {
    var value = getURLParameter(persistKey);
    if (value) {
        window.sessionStorage.setItem("persist:".concat(persistKey), value);
        clearURLParameter(persistKey);
    }
};
export var getStore = function (configId, ticket, computationApiBaseUrl, preloadedState) {
    var e_1, _a;
    try {
        for (var persistConfigs_1 = __values(persistConfigs), persistConfigs_1_1 = persistConfigs_1.next(); !persistConfigs_1_1.done; persistConfigs_1_1 = persistConfigs_1.next()) {
            var config = persistConfigs_1_1.value;
            storeURLParameter(config.key);
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (persistConfigs_1_1 && !persistConfigs_1_1.done && (_a = persistConfigs_1.return)) _a.call(persistConfigs_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    var store = configureStore({
        reducer: persistReducer({ key: "root", storage: storage, whitelist: [] }, persistedRootReducer),
        middleware: function (getDefaultMiddleware) {
            return getDefaultMiddleware({
                serializableCheck: false,
                thunk: { extraArgument: { configId: configId, ticket: ticket, computationApiBaseUrl: computationApiBaseUrl } },
            }).prepend(listenerMiddleware.middleware);
        },
        preloadedState: preloadedState,
    });
    var persistor = persistStore(store);
    return { store: store, persistor: persistor };
};
var store = getStore("tmp").store;
export var useAppDispatch = useDispatch;
export var useAppSelector = useSelector;

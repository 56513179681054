var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from "@emotion/react";
import styled from "@emotion/styled";
export var Image = styled.img(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var IconContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
export var ImageContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), function (_a) {
    var theme = _a.theme, selected = _a.selected;
    var imageDimension = 26;
    var iconMargin = 8;
    return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\t\t\tcursor: pointer;\n\n\t\t\t", " {\n\t\t\t\t", "\n\t\t\t\twidth: 135px;\n\t\t\t\theight: 135px;\n\t\t\t\taspect-ratio: 1/1;\n\t\t\t\tobject-fit: cover;\n\t\t\t\tborder-radius: ", ";\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tdisplay: ", ";\n\t\t\t\tposition: absolute;\n\t\t\t\tright: 0;\n\t\t\t\ttop: 0;\n\t\t\t\ttransform: translate(", "px, -", "px);\n\t\t\t\tbackground: ", ";\n\t\t\t\tborder-radius: 50%;\n\t\t\t\twidth: ", "px;\n\t\t\t\theight: ", "px;\n\t\t\t\talign-items: center;\n\t\t\t\tjustify-content: center;\n\n\t\t\t\tspan {\n\t\t\t\t\tdisplay: flex;\n\t\t\t\t\talign-items: center;\n\t\t\t\t}\n\t\t\t}\n\t\t"], ["\n\t\t\tcursor: pointer;\n\n\t\t\t", " {\n\t\t\t\t", "\n\t\t\t\twidth: 135px;\n\t\t\t\theight: 135px;\n\t\t\t\taspect-ratio: 1/1;\n\t\t\t\tobject-fit: cover;\n\t\t\t\tborder-radius: ", ";\n\t\t\t}\n\n\t\t\t", " {\n\t\t\t\tdisplay: ", ";\n\t\t\t\tposition: absolute;\n\t\t\t\tright: 0;\n\t\t\t\ttop: 0;\n\t\t\t\ttransform: translate(", "px, -", "px);\n\t\t\t\tbackground: ", ";\n\t\t\t\tborder-radius: 50%;\n\t\t\t\twidth: ", "px;\n\t\t\t\theight: ", "px;\n\t\t\t\talign-items: center;\n\t\t\t\tjustify-content: center;\n\n\t\t\t\tspan {\n\t\t\t\t\tdisplay: flex;\n\t\t\t\t\talign-items: center;\n\t\t\t\t}\n\t\t\t}\n\t\t"])), Image, selected &&
        "box-shadow: 0 0 0 2px ".concat(theme.colors.palette.green[500], ";"), theme.dimensions.borderRadius, IconContainer, selected ? "flex" : "none", iconMargin, iconMargin, theme.colors.palette.green[500], imageDimension, imageDimension);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;

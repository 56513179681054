import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useEffect, useRef } from "react";
import * as Styled from "./ps-es-tooltip.style";
import { useNumberConversion } from "hooks/number-conversion";
import { selectors, useAppSelector } from "store";
import { Unit } from "utility/number";
import { CanvasOverlay } from "ui/components/panels/canvas/overlay-elements/canvas-overlay";
import { energySavingColor, partSecuredColor } from "store/types";
import { systemDataConstants } from "api/system-data";
import { TextBoxWithEditableTooltip } from "ui/components/elements/chart/text-box";
import { usePlaycanvasApplicationContext } from "providers/playcanvas-application";
export var PsEsTooltip = function (_a) {
    var _b;
    var anchorX = _a.anchorX, anchorY = _a.anchorY;
    var blockCount = useRef(0);
    var blockingElementEvents = usePlaycanvasApplicationContext().blockingElementEvents;
    var showEnergySavePerformance = useAppSelector(selectors.selectShowEnergySave);
    var dashboardData = useAppSelector(selectors.selectDashboardData);
    var convertNumber = useNumberConversion();
    var partSecuredLevel = useAppSelector(selectors.selectPartSecuredLevel);
    var convertedPartSecuredLevel = convertNumber(partSecuredLevel, Unit.KPA);
    var convertedESLevel = ((_b = dashboardData === null || dashboardData === void 0 ? void 0 : dashboardData.desiredVacuumLevels.energySaving) === null || _b === void 0 ? void 0 : _b.value)
        ? convertNumber(dashboardData.desiredVacuumLevels.energySaving.value, Unit.KPA)
        : undefined;
    var textBoxWidth = 20;
    var textBoxHeight = 20;
    var width = textBoxWidth * (showEnergySavePerformance ? 2.5 : 1); // if showing two boxes, use a 2.5 factor to have half a box for padding
    // remove potential existing blocks on unmount
    useEffect(function () {
        return function () {
            for (var i = 0; i < blockCount.current; i++) {
                blockingElementEvents === null || blockingElementEvents === void 0 ? void 0 : blockingElementEvents.onBlockingElementExit();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsx(CanvasOverlay, { x: anchorX - width / 2, y: anchorY - textBoxHeight * 2, children: _jsxs(Styled.TextBoxContainer, { width: width, onMouseEnter: function () {
                blockCount.current += 1;
                blockingElementEvents === null || blockingElementEvents === void 0 ? void 0 : blockingElementEvents.onBlockingElementEnter();
            }, onMouseLeave: function () {
                blockCount.current -= 1;
                blockingElementEvents === null || blockingElementEvents === void 0 ? void 0 : blockingElementEvents.onBlockingElementExit();
            }, children: [_jsx("svg", { width: textBoxWidth, height: textBoxHeight, children: _jsx(TextBoxWithEditableTooltip, { parameter: systemDataConstants.parameterNames.partSecuredLevel, x: 0, y: 0, width: textBoxWidth, height: textBoxHeight, text: convertedPartSecuredLevel.value.toString(), color: partSecuredColor }) }), showEnergySavePerformance && convertedESLevel && (_jsx("svg", { width: textBoxWidth, height: textBoxHeight, children: _jsx(TextBoxWithEditableTooltip, { parameter: systemDataConstants.parameterNames.energySavingLevel, x: 0, y: 0, width: textBoxWidth, height: textBoxHeight, text: convertedESLevel.value.toString(), color: energySavingColor }) }))] }) }));
};
